/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ArticleSortOrder {
    ExternalNumber = 'externalNumber',
    Number = 'number',
    Name = 'name'
}

export function ArticleSortOrderFromJSON(json: any): ArticleSortOrder {
    return ArticleSortOrderFromJSONTyped(json, false);
}

export function ArticleSortOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleSortOrder {
    return json as ArticleSortOrder;
}

export function ArticleSortOrderToJSON(value?: ArticleSortOrder | null): any {
    return value as any;
}

