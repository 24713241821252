/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Branch,
    BranchFromJSON,
    BranchFromJSONTyped,
    BranchToJSON,
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
    CostCenter,
    CostCenterFromJSON,
    CostCenterFromJSONTyped,
    CostCenterToJSON,
    CustomerRule,
    CustomerRuleFromJSON,
    CustomerRuleFromJSONTyped,
    CustomerRuleToJSON,
    Employee,
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
    Facility,
    FacilityFromJSON,
    FacilityFromJSONTyped,
    FacilityToJSON,
    FileLink,
    FileLinkFromJSON,
    FileLinkFromJSONTyped,
    FileLinkToJSON,
    InvoiceMethod,
    InvoiceMethodFromJSON,
    InvoiceMethodFromJSONTyped,
    InvoiceMethodToJSON,
    InvoiceType,
    InvoiceTypeFromJSON,
    InvoiceTypeFromJSONTyped,
    InvoiceTypeToJSON,
    Person,
    PersonFromJSON,
    PersonFromJSONTyped,
    PersonToJSON,
    PriceRule,
    PriceRuleFromJSON,
    PriceRuleFromJSONTyped,
    PriceRuleToJSON,
    ProfitCenter,
    ProfitCenterFromJSON,
    ProfitCenterFromJSONTyped,
    ProfitCenterToJSON,
    VatCode,
    VatCodeFromJSON,
    VatCodeFromJSONTyped,
    VatCodeToJSON,
    VatType,
    VatTypeFromJSON,
    VatTypeFromJSONTyped,
    VatTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    name?: string | null;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Customer
     */
    categories?: Array<Category> | null;
    /**
     * 
     * @type {Person}
     * @memberof Customer
     */
    mainContact?: Person | null;
    /**
     * 
     * @type {Array<Person>}
     * @memberof Customer
     */
    readonly contacts?: Array<Person> | null;
    /**
     * 
     * @type {Array<Facility>}
     * @memberof Customer
     */
    readonly facilities?: Array<Facility> | null;
    /**
     * 
     * @type {Array<Branch>}
     * @memberof Customer
     */
    branches?: Array<Branch> | null;
    /**
     * 
     * @type {Array<FileLink>}
     * @memberof Customer
     */
    readonly documents?: Array<FileLink> | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    paymentTerms?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    deliveryTerms?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    delayInterestRate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    website?: string | null;
    /**
     * 
     * @type {VatCode}
     * @memberof Customer
     */
    vat?: VatCode | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    orgNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    visitingAddress?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof Customer
     */
    postalAddress?: Address | null;
    /**
     * 
     * @type {Address}
     * @memberof Customer
     */
    invoiceAddress?: Address | null;
    /**
     * 
     * @type {PriceRule}
     * @memberof Customer
     */
    priceRule?: PriceRule | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    searchTerm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    phonenumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    warning?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    _private?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    active?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    reverseTax?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    collectiveInvoice?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    emailInvoice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    gln?: string | null;
    /**
     * 
     * @type {InvoiceMethod}
     * @memberof Customer
     */
    invoiceMethod?: InvoiceMethod | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    extId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    currencyCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    countryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    language?: string | null;
    /**
     * 
     * @type {ProfitCenter}
     * @memberof Customer
     */
    profitCenter?: ProfitCenter | null;
    /**
     * 
     * @type {Employee}
     * @memberof Customer
     */
    ourReference?: Employee | null;
    /**
     * 
     * @type {CostCenter}
     * @memberof Customer
     */
    costCenter?: CostCenter | null;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    prospect?: boolean | null;
    /**
     * 
     * @type {Employee}
     * @memberof Customer
     */
    salesPerson?: Employee | null;
    /**
     * 
     * @type {CustomerRule}
     * @memberof Customer
     */
    customerRule?: CustomerRule | null;
    /**
     * 
     * @type {InvoiceType}
     * @memberof Customer
     */
    invoiceType?: InvoiceType | null;
    /**
     * 
     * @type {VatType}
     * @memberof Customer
     */
    region?: VatType | null;
}

export function CustomerFromJSON(json: any): Customer {
    return CustomerFromJSONTyped(json, false);
}

export function CustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Customer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'categories': !exists(json, 'categories') ? undefined : (json['categories'] === null ? null : (json['categories'] as Array<any>).map(CategoryFromJSON)),
        'mainContact': !exists(json, 'mainContact') ? undefined : PersonFromJSON(json['mainContact']),
        'contacts': !exists(json, 'contacts') ? undefined : (json['contacts'] === null ? null : (json['contacts'] as Array<any>).map(PersonFromJSON)),
        'facilities': !exists(json, 'facilities') ? undefined : (json['facilities'] === null ? null : (json['facilities'] as Array<any>).map(FacilityFromJSON)),
        'branches': !exists(json, 'branches') ? undefined : (json['branches'] === null ? null : (json['branches'] as Array<any>).map(BranchFromJSON)),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(FileLinkFromJSON)),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'paymentTerms': !exists(json, 'paymentTerms') ? undefined : json['paymentTerms'],
        'deliveryTerms': !exists(json, 'deliveryTerms') ? undefined : json['deliveryTerms'],
        'delayInterestRate': !exists(json, 'delayInterestRate') ? undefined : json['delayInterestRate'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'vat': !exists(json, 'vat') ? undefined : VatCodeFromJSON(json['vat']),
        'orgNumber': !exists(json, 'orgNumber') ? undefined : json['orgNumber'],
        'visitingAddress': !exists(json, 'visitingAddress') ? undefined : json['visitingAddress'],
        'postalAddress': !exists(json, 'postalAddress') ? undefined : AddressFromJSON(json['postalAddress']),
        'invoiceAddress': !exists(json, 'invoiceAddress') ? undefined : AddressFromJSON(json['invoiceAddress']),
        'priceRule': !exists(json, 'priceRule') ? undefined : PriceRuleFromJSON(json['priceRule']),
        'searchTerm': !exists(json, 'searchTerm') ? undefined : json['searchTerm'],
        'phonenumber': !exists(json, 'phonenumber') ? undefined : json['phonenumber'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
        '_private': !exists(json, 'private') ? undefined : json['private'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'vatNumber': !exists(json, 'vatNumber') ? undefined : json['vatNumber'],
        'reverseTax': !exists(json, 'reverseTax') ? undefined : json['reverseTax'],
        'collectiveInvoice': !exists(json, 'collectiveInvoice') ? undefined : json['collectiveInvoice'],
        'emailInvoice': !exists(json, 'emailInvoice') ? undefined : json['emailInvoice'],
        'gln': !exists(json, 'gln') ? undefined : json['gln'],
        'invoiceMethod': !exists(json, 'invoiceMethod') ? undefined : InvoiceMethodFromJSON(json['invoiceMethod']),
        'extId': !exists(json, 'extId') ? undefined : json['extId'],
        'currencyCode': !exists(json, 'currencyCode') ? undefined : json['currencyCode'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'profitCenter': !exists(json, 'profitCenter') ? undefined : ProfitCenterFromJSON(json['profitCenter']),
        'ourReference': !exists(json, 'ourReference') ? undefined : EmployeeFromJSON(json['ourReference']),
        'costCenter': !exists(json, 'costCenter') ? undefined : CostCenterFromJSON(json['costCenter']),
        'prospect': !exists(json, 'prospect') ? undefined : json['prospect'],
        'salesPerson': !exists(json, 'salesPerson') ? undefined : EmployeeFromJSON(json['salesPerson']),
        'customerRule': !exists(json, 'customerRule') ? undefined : CustomerRuleFromJSON(json['customerRule']),
        'invoiceType': !exists(json, 'invoiceType') ? undefined : InvoiceTypeFromJSON(json['invoiceType']),
        'region': !exists(json, 'region') ? undefined : VatTypeFromJSON(json['region']),
    };
}

export function CustomerToJSON(value?: Customer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'name': value.name,
        'categories': value.categories === undefined ? undefined : (value.categories === null ? null : (value.categories as Array<any>).map(CategoryToJSON)),
        'mainContact': PersonToJSON(value.mainContact),
        'branches': value.branches === undefined ? undefined : (value.branches === null ? null : (value.branches as Array<any>).map(BranchToJSON)),
        'note': value.note,
        'paymentTerms': value.paymentTerms,
        'deliveryTerms': value.deliveryTerms,
        'delayInterestRate': value.delayInterestRate,
        'email': value.email,
        'website': value.website,
        'vat': VatCodeToJSON(value.vat),
        'orgNumber': value.orgNumber,
        'visitingAddress': value.visitingAddress,
        'postalAddress': AddressToJSON(value.postalAddress),
        'invoiceAddress': AddressToJSON(value.invoiceAddress),
        'priceRule': PriceRuleToJSON(value.priceRule),
        'searchTerm': value.searchTerm,
        'phonenumber': value.phonenumber,
        'warning': value.warning,
        'private': value._private,
        'active': value.active,
        'vatNumber': value.vatNumber,
        'reverseTax': value.reverseTax,
        'collectiveInvoice': value.collectiveInvoice,
        'emailInvoice': value.emailInvoice,
        'gln': value.gln,
        'invoiceMethod': InvoiceMethodToJSON(value.invoiceMethod),
        'extId': value.extId,
        'currencyCode': value.currencyCode,
        'countryCode': value.countryCode,
        'language': value.language,
        'profitCenter': ProfitCenterToJSON(value.profitCenter),
        'ourReference': EmployeeToJSON(value.ourReference),
        'costCenter': CostCenterToJSON(value.costCenter),
        'prospect': value.prospect,
        'salesPerson': EmployeeToJSON(value.salesPerson),
        'customerRule': CustomerRuleToJSON(value.customerRule),
        'invoiceType': InvoiceTypeToJSON(value.invoiceType),
        'region': VatTypeToJSON(value.region),
    };
}


