/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Inventory is the Warehouse/Car/Container itself
 * @export
 * @interface Inventory
 */
export interface Inventory {
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Inventory
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Inventory
     */
    mainInventory?: boolean | null;
}

export function InventoryFromJSON(json: any): Inventory {
    return InventoryFromJSONTyped(json, false);
}

export function InventoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Inventory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'mainInventory': !exists(json, 'mainInventory') ? undefined : json['mainInventory'],
    };
}

export function InventoryToJSON(value?: Inventory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'name': value.name,
        'mainInventory': value.mainInventory,
    };
}


