/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VentilationSystem
 */
export interface VentilationSystem {
    /**
     * 
     * @type {string}
     * @memberof VentilationSystem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VentilationSystem
     */
    facilityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VentilationSystem
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VentilationSystem
     */
    type?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VentilationSystem
     */
    systemCategory?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VentilationSystem
     */
    activeOvk?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof VentilationSystem
     */
    buildingStandard?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof VentilationSystem
     */
    nextOvkDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof VentilationSystem
     */
    previousOvkDate?: Date | null;
}

export function VentilationSystemFromJSON(json: any): VentilationSystem {
    return VentilationSystemFromJSONTyped(json, false);
}

export function VentilationSystemFromJSONTyped(json: any, ignoreDiscriminator: boolean): VentilationSystem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facilityId': !exists(json, 'facilityId') ? undefined : json['facilityId'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'systemCategory': !exists(json, 'systemCategory') ? undefined : json['systemCategory'],
        'activeOvk': !exists(json, 'activeOvk') ? undefined : json['activeOvk'],
        'buildingStandard': !exists(json, 'buildingStandard') ? undefined : json['buildingStandard'],
        'nextOvkDate': !exists(json, 'nextOvkDate') ? undefined : (json['nextOvkDate'] === null ? null : new Date(json['nextOvkDate'])),
        'previousOvkDate': !exists(json, 'previousOvkDate') ? undefined : (json['previousOvkDate'] === null ? null : new Date(json['previousOvkDate'])),
    };
}

export function VentilationSystemToJSON(value?: VentilationSystem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facilityId': value.facilityId,
        'number': value.number,
        'type': value.type,
        'systemCategory': value.systemCategory,
        'activeOvk': value.activeOvk,
        'buildingStandard': value.buildingStandard,
        'nextOvkDate': value.nextOvkDate === undefined ? undefined : (value.nextOvkDate === null ? null : value.nextOvkDate.toISOString()),
        'previousOvkDate': value.previousOvkDate === undefined ? undefined : (value.previousOvkDate === null ? null : value.previousOvkDate.toISOString()),
    };
}


