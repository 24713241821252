




























import Vue from 'vue';
import { SDFData, SDFDataRequestError } from '../SDFData';
import _debounce from 'lodash.debounce';
import Facility from './Facility.vue';

export default Vue.component('facility-list', {
    components: { Facility },
    data(): any {
        return {
            loadSuccessful: false,
            loadError: '',
            currentId: '',
            list: [],
            nextLink: null,
            filteredList: [],
            filterText: '',
            filterStatusAll: true,
            filterStatusNotTaken: false,
            filterStatusTaken: false,
            filterStatusFinished: false,
            filterFacilityId: null
        }
    },
    mounted: function () {
        this.updateList(true);
    },
    created () {
        window.addEventListener('scroll', this.loadMoreDebounce);
    },
    destroyed () {
        window.removeEventListener('scroll', this.loadMoreDebounce);
    },

    computed: {
        filtersActive(): boolean {
            return this.filterText != '';
        }
    },

    watch: {

        filterText() {
            _debounce(function (this: any) { this.updateList(true); }, 500).call(this);
        }

    },

    methods: {

        updateList(clear: boolean) {
            const self = this;
            const queryList = new Array<string>();

            if (clear) {
                this.nextLink = 'facilities?' + 'filterText=' + encodeURIComponent(this.filterText);
            }

            SDFData.request(this.nextLink)
                .then(function (json: any) {
                    self.nextLink = json.nextLink;

                    if (clear) self.list = json.items;
                    else self.list.push.apply(self.list, json.items);

                    self.loadSuccessful = true;
                    self.filteredList = self.list;
                    self.loadMoreDebounce();
                    SDFData.setBreadCrumb(self.$route.path, 'Ärenden');
                })
                .catch(function (error: any) {
                    self.loadError = JSON.stringify(error);
                    self.loadSuccessful = false;
                });
        },

        clearFilter() {
            this.filterText = '';
        },

        loadMoreDebounce: _debounce(function (this: any) {
            if (!this.nextLink) return;

            // https://alligator.io/vuejs/implementing-infinite-scroll/
            //let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;
            const margin = 1000;
            const bottomOfWindow = (window.innerHeight + window.pageYOffset) >= document.documentElement.scrollHeight - margin;
            if (!bottomOfWindow) return;

            this.updateList(false);
        }, 100),

        getRelativeDate(date: string) {
            return SDFData.getRelativeDate(new Date(date));
        }
    }
});
