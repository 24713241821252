/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkOrderSortOrder {
    Number = 'number',
    Facility = 'facility',
    CreatedDate = 'createdDate',
    StartDate = 'startDate',
    ChangeDate = 'changeDate',
    WorkOrderType = 'workOrderType'
}

export function WorkOrderSortOrderFromJSON(json: any): WorkOrderSortOrder {
    return WorkOrderSortOrderFromJSONTyped(json, false);
}

export function WorkOrderSortOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkOrderSortOrder {
    return json as WorkOrderSortOrder;
}

export function WorkOrderSortOrderToJSON(value?: WorkOrderSortOrder | null): any {
    return value as any;
}

