














































import Vue from 'vue';
import { SDFData, SDFDataRequestError } from '../SDFData';
import _debounce from 'lodash.debounce';

export default Vue.extend({
    data() {
        return {
            loadSuccessful: false,
            loadError: '',
            currentId: '',
            list: [],
            nextLink: ''
        }
    },
    mounted: function () {
        this.updateList(true);
    },
    created () {
        window.addEventListener('scroll', this.loadMoreDebounce);
    },
    destroyed () {
        window.removeEventListener('scroll', this.loadMoreDebounce);
    },

    methods: {

        updateList(clear: boolean) {
            const self = this;
            const queryList = new Array<string>();

            if (!this.nextLink || clear) {
                this.nextLink = 'projects';
            }

            SDFData.request(this.nextLink)
                .then(function (json: any) {
                    self.nextLink = json.nextLink;
                    if (self.nextLink && self.nextLink.startsWith('/api/')) self.nextLink = self.nextLink.substring(5);

                    if (clear) self.list = json.items;
                    else self.list.push.apply(self.list, json.items);

                    self.loadSuccessful = true;
                    self.loadMoreDebounce();
                })
                .catch(function (error: any) {
                    self.loadError = JSON.stringify(error);
                    self.loadSuccessful = false;
                });
        },

        loadMoreDebounce: _debounce(function (this: any) {
            if (!this.nextLink) return;

            const margin = 1000;
            const bottomOfWindow = (window.innerHeight + window.pageYOffset) >= document.documentElement.scrollHeight - margin;
            if (!bottomOfWindow) return;

            this.updateList(false);
        }, 100),

        getRelativeDate(date: string) {
            return SDFData.getRelativeDate(new Date(date));
        }
    }
});
