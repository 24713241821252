var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item)?_c('div',[_c('h1',{attrs:{"title":_vm.item.name + ' - ' + _vm.item.area}},[_c('a',{staticClass:"back-button",attrs:{"title":"Tillbaka","href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('i',{staticClass:"fas fa-bars"}),_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('table',{staticClass:"details",staticStyle:{"border-top":"1px solid lightgray","padding-top":"15px"}},[_vm._m(0),_c('tr',[_c('th',[_vm._v("Pris")]),_c('td',{staticClass:"valuecell"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.item.priceEstimate)))]),_c('td',{staticClass:"valuecell"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.item.price)))])]),(_vm.showCost)?_c('tr',[_c('th',[_vm._v("Kostnad")]),_c('td',{staticClass:"valuecell"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.item.costEstimate)))]),_c('td',{staticClass:"valuecell"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.item.cost)))])]):_vm._e(),_c('tr',[_c('th',[_vm._v("Timmar")]),_c('td',{staticClass:"valuecell"},[_vm._v(_vm._s(_vm.item.hoursEstimate))]),_c('td',{staticClass:"valuecell"},[_vm._v(_vm._s(_vm.item.hours))])])]),(
        _vm.showCost && _vm.item.costingHeadlines && _vm.item.costingHeadlines.length > 0
      )?_c('h2',[_vm._v(" Rubriker ")]):_vm._e(),(
        _vm.showCost && _vm.item.costingHeadlines && _vm.item.costingHeadlines.length > 0
      )?_c('table',{staticClass:"details"},[_vm._m(1),_vm._l((_vm.item.costingHeadlines),function(costingHeadline){return _c('tr',{key:costingHeadline.id},[_c('th',[_vm._v(_vm._s(costingHeadline.title))]),_c('td',{staticClass:"valuecell"},[_vm._v(" "+_vm._s(_vm.formatCurrency(costingHeadline.costEstimate))+" ")]),_c('td',{staticClass:"valuecell"},[_vm._v(" "+_vm._s(_vm.formatCurrency(costingHeadline.costResult))+" ")])])})],2):_vm._e(),(
        _vm.showSupplierInvoices &&
        _vm.supplierInvoices &&
        _vm.supplierInvoices.length > 0
      )?_c('h2',[_vm._v(" Leverantörsfakturor ")]):_vm._e(),(
        _vm.showSupplierInvoices &&
        _vm.supplierInvoices &&
        _vm.supplierInvoices.length > 0
      )?_c('table',{staticClass:"details"},[_vm._m(2),_vm._l((_vm.supplierInvoices),function(supplierInvoice){return _c('tr',{key:supplierInvoice.id},[_c('td',{staticClass:"valuecell"},[_c('a',{attrs:{"href":supplierInvoice.path ? supplierInvoice.path : undefined}},[_vm._v(_vm._s(supplierInvoice.invoiceNumber))])]),_c('td',{staticClass:"valuecell"},[_c('a',{attrs:{"href":supplierInvoice.path ? supplierInvoice.path : undefined}},[_vm._v(_vm._s(supplierInvoice.name))])]),_c('td',{staticClass:"valuecell"},[_vm._v(" "+_vm._s(_vm.formatCurrency(supplierInvoice.totalAmount))+" ")])])})],2):_vm._e()]):_vm._e(),(_vm.error)?_c('div',[_vm._v("Kunde inte ladda projekt: "+_vm._s(_vm.error))]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th'),_c('th',[_vm._v("Beräknat")]),_c('th',[_vm._v("Utfall")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th'),_c('th',[_vm._v("Beräknat")]),_c('th',[_vm._v("Utfall")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("Fakturanr")]),_c('th',[_vm._v("Namn")]),_c('th',[_vm._v("Kostnad")])])}]

export { render, staticRenderFns }