/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkOrderRecurrenceIntervalType {
    Year = 'year',
    Month = 'month',
    Week = 'week'
}

export function WorkOrderRecurrenceIntervalTypeFromJSON(json: any): WorkOrderRecurrenceIntervalType {
    return WorkOrderRecurrenceIntervalTypeFromJSONTyped(json, false);
}

export function WorkOrderRecurrenceIntervalTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkOrderRecurrenceIntervalType {
    return json as WorkOrderRecurrenceIntervalType;
}

export function WorkOrderRecurrenceIntervalTypeToJSON(value?: WorkOrderRecurrenceIntervalType | null): any {
    return value as any;
}

