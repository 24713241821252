/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArticleDiscountGroup
 */
export interface ArticleDiscountGroup {
    /**
     * 
     * @type {string}
     * @memberof ArticleDiscountGroup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleDiscountGroup
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleDiscountGroup
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ArticleDiscountGroup
     */
    discount: number;
}

export function ArticleDiscountGroupFromJSON(json: any): ArticleDiscountGroup {
    return ArticleDiscountGroupFromJSONTyped(json, false);
}

export function ArticleDiscountGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleDiscountGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'name': json['name'],
        'discount': json['discount'],
    };
}

export function ArticleDiscountGroupToJSON(value?: ArticleDiscountGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'name': value.name,
        'discount': value.discount,
    };
}


