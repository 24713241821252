/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Protocol,
    ProtocolFromJSON,
    ProtocolFromJSONTyped,
    ProtocolToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkObjectType
 */
export interface WorkObjectType {
    /**
     * 
     * @type {string}
     * @memberof WorkObjectType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectType
     */
    color?: string | null;
    /**
     * The internal objekt key linked to OBJEKT.Objekttyp.
     * @type {number}
     * @memberof WorkObjectType
     */
    number: number;
    /**
     * Default checklist of work objects created with this type.
     * @type {Protocol}
     * @memberof WorkObjectType
     */
    defaultProtocol?: Protocol | null;
    /**
     * Default notes of work objects created with this type.
     * @type {string}
     * @memberof WorkObjectType
     */
    defaultNotes?: string | null;
}

export function WorkObjectTypeFromJSON(json: any): WorkObjectType {
    return WorkObjectTypeFromJSONTyped(json, false);
}

export function WorkObjectTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkObjectType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'number': json['number'],
        'defaultProtocol': !exists(json, 'defaultProtocol') ? undefined : ProtocolFromJSON(json['defaultProtocol']),
        'defaultNotes': !exists(json, 'defaultNotes') ? undefined : json['defaultNotes'],
    };
}

export function WorkObjectTypeToJSON(value?: WorkObjectType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'color': value.color,
        'number': value.number,
        'defaultProtocol': ProtocolToJSON(value.defaultProtocol),
        'defaultNotes': value.defaultNotes,
    };
}


