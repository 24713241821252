/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Branch,
    BranchFromJSON,
    BranchFromJSONTyped,
    BranchToJSON,
    EmployeeLoginStatus,
    EmployeeLoginStatusFromJSON,
    EmployeeLoginStatusFromJSONTyped,
    EmployeeLoginStatusToJSON,
    FileLink,
    FileLinkFromJSON,
    FileLinkFromJSONTyped,
    FileLinkToJSON,
    Person,
    PersonFromJSON,
    PersonFromJSONTyped,
    PersonToJSON,
} from './';

/**
 * 
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    initials?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    employeeNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    employed?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    profession?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    phonenumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    cellPhonenumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    technician: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    salesPerson?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    projectLeader: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    filterMineCompleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    filterMineAttested: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    filterMineReceipted: boolean;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    normalWorkHours?: number | null;
    /**
     * 
     * @type {EmployeeLoginStatus}
     * @memberof Employee
     */
    loginStatus?: EmployeeLoginStatus | null;
    /**
     * 
     * @type {Address}
     * @memberof Employee
     */
    address?: Address | null;
    /**
     * 
     * @type {Branch}
     * @memberof Employee
     */
    defaultBranch?: Branch | null;
    /**
     * 
     * @type {Person}
     * @memberof Employee
     */
    closestRelative?: Person | null;
    /**
     * 
     * @type {Array<FileLink>}
     * @memberof Employee
     */
    documents?: Array<FileLink> | null;
    /**
     * 
     * @type {Array<Branch>}
     * @memberof Employee
     */
    branches: Array<Branch>;
}

export function EmployeeFromJSON(json: any): Employee {
    return EmployeeFromJSONTyped(json, false);
}

export function EmployeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Employee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'initials': !exists(json, 'initials') ? undefined : json['initials'],
        'employeeNumber': !exists(json, 'employeeNumber') ? undefined : json['employeeNumber'],
        'employed': !exists(json, 'employed') ? undefined : json['employed'],
        'profession': !exists(json, 'profession') ? undefined : json['profession'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phonenumber': !exists(json, 'phonenumber') ? undefined : json['phonenumber'],
        'cellPhonenumber': !exists(json, 'cellPhonenumber') ? undefined : json['cellPhonenumber'],
        'technician': json['technician'],
        'salesPerson': !exists(json, 'salesPerson') ? undefined : json['salesPerson'],
        'projectLeader': json['projectLeader'],
        'filterMineCompleted': json['filterMineCompleted'],
        'filterMineAttested': json['filterMineAttested'],
        'filterMineReceipted': json['filterMineReceipted'],
        'normalWorkHours': !exists(json, 'normalWorkHours') ? undefined : json['normalWorkHours'],
        'loginStatus': !exists(json, 'loginStatus') ? undefined : EmployeeLoginStatusFromJSON(json['loginStatus']),
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'defaultBranch': !exists(json, 'defaultBranch') ? undefined : BranchFromJSON(json['defaultBranch']),
        'closestRelative': !exists(json, 'closestRelative') ? undefined : PersonFromJSON(json['closestRelative']),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(FileLinkFromJSON)),
        'branches': ((json['branches'] as Array<any>).map(BranchFromJSON)),
    };
}

export function EmployeeToJSON(value?: Employee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'initials': value.initials,
        'employeeNumber': value.employeeNumber,
        'employed': value.employed,
        'profession': value.profession,
        'email': value.email,
        'phonenumber': value.phonenumber,
        'cellPhonenumber': value.cellPhonenumber,
        'technician': value.technician,
        'salesPerson': value.salesPerson,
        'projectLeader': value.projectLeader,
        'filterMineCompleted': value.filterMineCompleted,
        'filterMineAttested': value.filterMineAttested,
        'filterMineReceipted': value.filterMineReceipted,
        'normalWorkHours': value.normalWorkHours,
        'loginStatus': EmployeeLoginStatusToJSON(value.loginStatus),
        'address': AddressToJSON(value.address),
        'defaultBranch': BranchToJSON(value.defaultBranch),
        'closestRelative': PersonToJSON(value.closestRelative),
        'documents': value.documents === undefined ? undefined : (value.documents === null ? null : (value.documents as Array<any>).map(FileLinkToJSON)),
        'branches': ((value.branches as Array<any>).map(BranchToJSON)),
    };
}


