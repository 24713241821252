/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Person,
    PersonFromJSON,
    PersonFromJSONTyped,
    PersonToJSON,
} from './';

/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    email?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof Supplier
     */
    address?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    phonenumber?: string | null;
    /**
     * 
     * @type {Person}
     * @memberof Supplier
     */
    contactPerson?: Person | null;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    bankgiroNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    postgiroNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    organisationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Supplier
     */
    ediSupplier: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Supplier
     */
    refrigerantSupplier: boolean;
    /**
     * 
     * @type {Array<Person>}
     * @memberof Supplier
     */
    readonly contacts?: Array<Person> | null;
}

export function SupplierFromJSON(json: any): Supplier {
    return SupplierFromJSONTyped(json, false);
}

export function SupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): Supplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'phonenumber': !exists(json, 'phonenumber') ? undefined : json['phonenumber'],
        'contactPerson': !exists(json, 'contactPerson') ? undefined : PersonFromJSON(json['contactPerson']),
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'bankgiroNumber': !exists(json, 'bankgiroNumber') ? undefined : json['bankgiroNumber'],
        'postgiroNumber': !exists(json, 'postgiroNumber') ? undefined : json['postgiroNumber'],
        'organisationNumber': !exists(json, 'organisationNumber') ? undefined : json['organisationNumber'],
        'vatNumber': !exists(json, 'vatNumber') ? undefined : json['vatNumber'],
        'ediSupplier': json['ediSupplier'],
        'refrigerantSupplier': json['refrigerantSupplier'],
        'contacts': !exists(json, 'contacts') ? undefined : (json['contacts'] === null ? null : (json['contacts'] as Array<any>).map(PersonFromJSON)),
    };
}

export function SupplierToJSON(value?: Supplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'address': AddressToJSON(value.address),
        'phonenumber': value.phonenumber,
        'contactPerson': PersonToJSON(value.contactPerson),
        'externalId': value.externalId,
        'bankgiroNumber': value.bankgiroNumber,
        'postgiroNumber': value.postgiroNumber,
        'organisationNumber': value.organisationNumber,
        'vatNumber': value.vatNumber,
        'ediSupplier': value.ediSupplier,
        'refrigerantSupplier': value.refrigerantSupplier,
    };
}


