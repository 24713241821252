/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RefrigerantYearlyReportSendType {
    Print = 'print',
    PDF = 'pdf',
    Email = 'email',
    DigitalSignature = 'digitalSignature'
}

export function RefrigerantYearlyReportSendTypeFromJSON(json: any): RefrigerantYearlyReportSendType {
    return RefrigerantYearlyReportSendTypeFromJSONTyped(json, false);
}

export function RefrigerantYearlyReportSendTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefrigerantYearlyReportSendType {
    return json as RefrigerantYearlyReportSendType;
}

export function RefrigerantYearlyReportSendTypeToJSON(value?: RefrigerantYearlyReportSendType | null): any {
    return value as any;
}

