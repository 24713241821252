/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Article,
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkObjectComponent
 */
export interface WorkObjectComponent {
    /**
     * 
     * @type {string}
     * @memberof WorkObjectComponent
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectComponent
     */
    quantity?: number | null;
    /**
     * 
     * @type {Article}
     * @memberof WorkObjectComponent
     */
    article?: Article | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectComponent
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectComponent
     */
    designation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WorkObjectComponent
     */
    changeDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectComponent
     */
    manufactureName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectComponent
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectComponent
     */
    objectId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectComponent
     */
    row?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkObjectComponent
     */
    sparePart: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectComponent
     */
    type?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof WorkObjectComponent
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WorkObjectComponent
     */
    finishDate?: Date | null;
}

export function WorkObjectComponentFromJSON(json: any): WorkObjectComponent {
    return WorkObjectComponentFromJSONTyped(json, false);
}

export function WorkObjectComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkObjectComponent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'article': !exists(json, 'article') ? undefined : ArticleFromJSON(json['article']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'designation': !exists(json, 'designation') ? undefined : json['designation'],
        'changeDate': !exists(json, 'changeDate') ? undefined : (json['changeDate'] === null ? null : new Date(json['changeDate'])),
        'manufactureName': !exists(json, 'manufactureName') ? undefined : json['manufactureName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'row': !exists(json, 'row') ? undefined : json['row'],
        'sparePart': json['sparePart'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'finishDate': !exists(json, 'finishDate') ? undefined : (json['finishDate'] === null ? null : new Date(json['finishDate'])),
    };
}

export function WorkObjectComponentToJSON(value?: WorkObjectComponent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'quantity': value.quantity,
        'article': ArticleToJSON(value.article),
        'name': value.name,
        'designation': value.designation,
        'changeDate': value.changeDate === undefined ? undefined : (value.changeDate === null ? null : value.changeDate.toISOString()),
        'manufactureName': value.manufactureName,
        'note': value.note,
        'objectId': value.objectId,
        'row': value.row,
        'sparePart': value.sparePart,
        'type': value.type,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'finishDate': value.finishDate === undefined ? undefined : (value.finishDate === null ? null : value.finishDate.toISOString()),
    };
}


