/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Inventory location is the shelf where the product is in the Warehouse/Car/Container
 * @export
 * @interface InventoryLocation
 */
export interface InventoryLocation {
    /**
     * 
     * @type {string}
     * @memberof InventoryLocation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryLocation
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryLocation
     */
    name?: string | null;
}

export function InventoryLocationFromJSON(json: any): InventoryLocation {
    return InventoryLocationFromJSONTyped(json, false);
}

export function InventoryLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function InventoryLocationToJSON(value?: InventoryLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'name': value.name,
    };
}


