/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FacilitySortOrder {
    Name = 'name',
    Customer = 'customer',
    City = 'city',
    Number = 'number'
}

export function FacilitySortOrderFromJSON(json: any): FacilitySortOrder {
    return FacilitySortOrderFromJSONTyped(json, false);
}

export function FacilitySortOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilitySortOrder {
    return json as FacilitySortOrder;
}

export function FacilitySortOrderToJSON(value?: FacilitySortOrder | null): any {
    return value as any;
}

