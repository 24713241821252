/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Entities {
    Me = 'me',
    Articles = 'articles',
    SupplierArticleGroups = 'supplierArticleGroups',
    Customers = 'customers',
    SupplierInvoices = 'supplierInvoices',
    WorkOrders = 'workOrders',
    Projects = 'projects',
    WorkOrderRows = 'workOrderRows',
    CustomerInvoices = 'customerInvoices',
    Services = 'services',
    ProjectEstimates = 'projectEstimates',
    Contacts = 'contacts',
    Facilities = 'facilities',
    SalaryTransactions = 'salaryTransactions',
    WorkObjects = 'workObjects',
    FGasProtocols = 'fGasProtocols',
    Contracts = 'contracts',
    Employees = 'employees',
    ObligatoryVentilationControls = 'obligatoryVentilationControls',
    DrillingProtocols = 'drillingProtocols',
    OrderChecklists = 'orderChecklists',
    Bookings = 'bookings',
    RecurringVisits = 'recurringVisits',
    MeasurementTypes = 'measurementTypes',
    SuperArticles = 'superArticles',
    Measurements = 'measurements',
    Categories = 'categories',
    Issues = 'issues',
    Protocols = 'protocols',
    Branches = 'branches',
    PlannedEvents = 'plannedEvents',
    WorkOrderShifts = 'workOrderShifts',
    Documents = 'documents',
    WorkOrderPlans = 'workOrderPlans',
    Suppliers = 'suppliers',
    ArticleGroups = 'articleGroups',
    WasteObjects = 'wasteObjects',
    Offers = 'offers',
    Persons = 'persons',
    Calls = 'calls',
    Activities = 'activities',
    PurchaseOrders = 'purchaseOrders',
    Municipalities = 'municipalities',
    PriceRules = 'priceRules',
    ContractTypes = 'contractTypes',
    PriceIndices = 'priceIndices',
    InvoiceTemplates = 'invoiceTemplates',
    CustomerRules = 'customerRules',
    AccountGroups = 'accountGroups',
    Accounts = 'accounts',
    CostCenters = 'costCenters',
    ProfitCenters = 'profitCenters',
    VatCodes = 'vatCodes',
    TaxReductionTypes = 'taxReductionTypes',
    Refrigerants = 'refrigerants',
    RefrigerantChanges = 'refrigerantChanges',
    WorkObjectTypes = 'workObjectTypes',
    InventoryLocations = 'inventoryLocations',
    ArticleGroup = 'articleGroup',
    ArticleDiscountGroups = 'articleDiscountGroups',
    VentilationSystems = 'ventilationSystems',
    MeterTypes = 'meterTypes',
    Equipment = 'equipment',
    Inventories = 'inventories',
    Vehicles = 'vehicles',
    SalaryCodes = 'salaryCodes',
    Statistics = 'statistics',
    PurchaseOrderLinesDelivery = 'purchaseOrderLinesDelivery',
    Educations = 'educations',
    InvoiceTypes = 'invoiceTypes',
    AccountingTemplates = 'accountingTemplates',
    ApprovalTemplates = 'approvalTemplates',
    MessageTemplates = 'messageTemplates',
    Integrations = 'integrations',
    Events = 'events',
    InstructionManuals = 'instructionManuals',
    Manuals = 'manuals',
    ManualDocument = 'manualDocument',
    CompanyQualityManuals = 'companyQualityManuals',
    ProjectQualityManuals = 'projectQualityManuals'
}

export function EntitiesFromJSON(json: any): Entities {
    return EntitiesFromJSONTyped(json, false);
}

export function EntitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Entities {
    return json as Entities;
}

export function EntitiesToJSON(value?: Entities | null): any {
    return value as any;
}

