/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Customer,
    CustomerFromJSON,
    CustomerFromJSONTyped,
    CustomerToJSON,
} from './';

/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    profession?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Person
     */
    changeDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    email?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Person
     */
    birthdate?: Date | null;
    /**
     * 
     * @type {Address}
     * @memberof Person
     */
    address?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    homeAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    hometown?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    homeZipcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    homePhonenumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    cellPhonenumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    phonenumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    categories?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    employee?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    socSecurityNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    extId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    mailConsent?: boolean | null;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof Person
     */
    customers?: Array<Customer> | null;
}

export function PersonFromJSON(json: any): Person {
    return PersonFromJSONTyped(json, false);
}

export function PersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Person {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': json['active'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'profession': !exists(json, 'profession') ? undefined : json['profession'],
        'changeDate': !exists(json, 'changeDate') ? undefined : (json['changeDate'] === null ? null : new Date(json['changeDate'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'birthdate': !exists(json, 'birthdate') ? undefined : (json['birthdate'] === null ? null : new Date(json['birthdate'])),
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'homeAddress': !exists(json, 'homeAddress') ? undefined : json['homeAddress'],
        'hometown': !exists(json, 'hometown') ? undefined : json['hometown'],
        'homeZipcode': !exists(json, 'homeZipcode') ? undefined : json['homeZipcode'],
        'homePhonenumber': !exists(json, 'homePhonenumber') ? undefined : json['homePhonenumber'],
        'id': json['id'],
        'cellPhonenumber': !exists(json, 'cellPhonenumber') ? undefined : json['cellPhonenumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phonenumber': !exists(json, 'phonenumber') ? undefined : json['phonenumber'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'employee': !exists(json, 'employee') ? undefined : json['employee'],
        'socSecurityNumber': !exists(json, 'socSecurityNumber') ? undefined : json['socSecurityNumber'],
        'extId': !exists(json, 'extId') ? undefined : json['extId'],
        'mailConsent': !exists(json, 'mailConsent') ? undefined : json['mailConsent'],
        'customers': !exists(json, 'customers') ? undefined : (json['customers'] === null ? null : (json['customers'] as Array<any>).map(CustomerFromJSON)),
    };
}

export function PersonToJSON(value?: Person | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'note': value.note,
        'profession': value.profession,
        'changeDate': value.changeDate === undefined ? undefined : (value.changeDate === null ? null : value.changeDate.toISOString()),
        'email': value.email,
        'birthdate': value.birthdate === undefined ? undefined : (value.birthdate === null ? null : value.birthdate.toISOString()),
        'address': AddressToJSON(value.address),
        'homeAddress': value.homeAddress,
        'hometown': value.hometown,
        'homeZipcode': value.homeZipcode,
        'homePhonenumber': value.homePhonenumber,
        'id': value.id,
        'cellPhonenumber': value.cellPhonenumber,
        'name': value.name,
        'phonenumber': value.phonenumber,
        'categories': value.categories,
        'employee': value.employee,
        'socSecurityNumber': value.socSecurityNumber,
        'extId': value.extId,
        'mailConsent': value.mailConsent,
        'customers': value.customers === undefined ? undefined : (value.customers === null ? null : (value.customers as Array<any>).map(CustomerToJSON)),
    };
}


