/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CategoryType {
    Supplier = 'supplier',
    Customer = 'customer',
    Facility = 'facility',
    Person = 'person',
    Article = 'article',
    WorkOrder = 'workOrder',
    ProjectCosting = 'projectCosting',
    Project = 'project',
    Contract = 'contract',
    Event = 'event',
    WorkObject = 'workObject'
}

export function CategoryTypeFromJSON(json: any): CategoryType {
    return CategoryTypeFromJSONTyped(json, false);
}

export function CategoryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryType {
    return json as CategoryType;
}

export function CategoryTypeToJSON(value?: CategoryType | null): any {
    return value as any;
}

