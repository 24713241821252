




















































































































































































import Vue from 'vue';
import { SDFData, TaskStatus } from '../SDFData';
import _debounce from 'lodash.debounce';

const branches = SDFData.localStorageAsJson('companies');
const branch = (branches[0] && branches[0].name.name) || 'serviceföretaget';

export default Vue.component('task', {
  data(): any {
    return {
      TaskStatus: TaskStatus,
      branch,
      task: null,
      taskStr: '',
      error: '',
      showIssueInvoice: false,
      issueJson: {
        id: String,
        yourOrderNumber: String,
      },
    };
  },
  watch: {
    $route(to, from) {
      this.updateIssue();
    },
  },
  mounted: function () {
    this.updateIssue();
  },
  computed: {
    coordinates(): string{
      if(this.task.facility.coordinates != null){
        return '?longitude=' + this.task.facility.coordinates.longitude + '&latitude=' + this.task.facility.coordinates.latitude
      }
      else {
        return ''
      }
    }
  },
  methods: {
    updateIssue() {
      this.error = '';
      const files = this.$refs.files as HTMLInputElement | undefined;

      if (files) {
        files.value = '';
      }

      const self = this;
      SDFData.requestWithMeta('issues/' + this.$route.params.id)
        .then(function (response: any) {
          self.showIssueInvoice = !!response.meta.links['report/issuebasis'];
          self.task = response.data;
          self.taskStr = JSON.stringify(self.task, null, 2);
          SDFData.setBreadCrumb(self.$route.path, self.task.description);
          SDFData.CurrentState.facilityId = self.task.facility.id;
        })
        .catch(function (error: any) {
          self.task = null;
          self.error = JSON.stringify(error);
        });
    },

    cancelIssue() {
      if (!confirm('Ta bort denna felanmälan?')) {
        return;
      }

      const self = this;
      SDFData.request('issues/' + this.$route.params.id + '/cancel', {
        method: 'post',
      })
        .then(function () {
          self.updateIssue();
        })
        .catch(function (error: any) {
          self.error = JSON.stringify(error);
        });
    },

    updateOrderNumber: _debounce(function (this: any) {
      const self = this;
      SDFData.request('issues/' + this.$route.params.id, {
        method: 'PATCH',
        //headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          yourOrderNumber: this.task.yourOrderNumber,
        }),
      })
        .then(function (task: any) {
        })
        .catch(function (error: any) {
          self.error = JSON.stringify(error);
        });
    }, 500),

    openFile(path: string) {
      SDFData.downloadFile(path).catch(function (error: any) {
        alert(JSON.stringify(error));
      });
    },
    getRelativeDate(date: string) {
      return SDFData.getRelativeDate(new Date(date));
    },
    getStatusText(status: string) {
      return SDFData.getStatusText(status);
    },
    toDateStr(date: Date | null | undefined) {
      if(!date){
        return;
      }

      let formattedDate = SDFData.getDateStr(new Date(date));

      return formattedDate;
    },
    getFontAwesomeFromExt(ext: string) {
      const className = 'fas fa-';
      ext = ext.replace('pdf', 'file-pdf');
      ext = ext.replace('xlsx', 'table');
      ext = ext.replace('xls', 'table');
      ext = ext.replace('png', 'image');
      ext = ext.replace('jpeg', 'image');
      ext = ext.replace('jpg', 'image');
      ext = ext.replace('bmp', 'image');
      ext = ext.replace('gif', 'image');
      ext = ext.replace('docx', 'file-word');
      ext = ext.replace('doc', 'file-word');
      return className + ext;
    },
    uploadDocument() {
      const files = this.$refs.files as HTMLInputElement | undefined;

      if (!files || !files.files || files.files.length <= 0) {
        return;
      }

      const data = new FormData();

      for (let i = 0; i < files.files.length; i++) {
        data.append('files', files.files[i]);
      }

      SDFData.request('issues/' + this.task.id + '/documents', {
        method: 'post',
        body: data,
      })
        .then(() => this.updateIssue())
        .catch((error: any) => (this.error = JSON.stringify(error)));
    },
  },
});
