/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerRule
 */
export interface CustomerRule {
    /**
     * 
     * @type {string}
     * @memberof CustomerRule
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRule
     */
    name: string;
}

export function CustomerRuleFromJSON(json: any): CustomerRule {
    return CustomerRuleFromJSONTyped(json, false);
}

export function CustomerRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function CustomerRuleToJSON(value?: CustomerRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}


