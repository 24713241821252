/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OfferStatus {
    Inquiry = 'inquiry',
    Created = 'created',
    Sent = 'sent',
    Approved = 'approved',
    Rejected = 'rejected',
    Canceled = 'canceled',
    Replaced = 'replaced',
    CostProposal = 'costProposal'
}

export function OfferStatusFromJSON(json: any): OfferStatus {
    return OfferStatusFromJSONTyped(json, false);
}

export function OfferStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferStatus {
    return json as OfferStatus;
}

export function OfferStatusToJSON(value?: OfferStatus | null): any {
    return value as any;
}

