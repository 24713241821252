/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SalaryCategory {
    NormalTime = 'normalTime',
    OverTimeComp = 'overTimeComp',
    OverTimePaid = 'overTimePaid',
    CompTime = 'compTime',
    AbsenceTime = 'absenceTime',
    SickTime = 'sickTime',
    Other = 'other'
}

export function SalaryCategoryFromJSON(json: any): SalaryCategory {
    return SalaryCategoryFromJSONTyped(json, false);
}

export function SalaryCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalaryCategory {
    return json as SalaryCategory;
}

export function SalaryCategoryToJSON(value?: SalaryCategory | null): any {
    return value as any;
}

