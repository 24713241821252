/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SupplierInvoiceSortOrder {
    Number = 'number',
    InvoiceNumber = 'invoiceNumber',
    SupplierName = 'supplierName'
}

export function SupplierInvoiceSortOrderFromJSON(json: any): SupplierInvoiceSortOrder {
    return SupplierInvoiceSortOrderFromJSONTyped(json, false);
}

export function SupplierInvoiceSortOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierInvoiceSortOrder {
    return json as SupplierInvoiceSortOrder;
}

export function SupplierInvoiceSortOrderToJSON(value?: SupplierInvoiceSortOrder | null): any {
    return value as any;
}

