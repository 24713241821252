/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Article,
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface PriceRuleArticle
 */
export interface PriceRuleArticle {
    /**
     * 
     * @type {string}
     * @memberof PriceRuleArticle
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PriceRuleArticle
     */
    priceRuleId: string;
    /**
     * 
     * @type {Article}
     * @memberof PriceRuleArticle
     */
    article?: Article | null;
    /**
     * 
     * @type {Array<Category>}
     * @memberof PriceRuleArticle
     */
    employeeCategories?: Array<Category> | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleArticle
     */
    sellingPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleArticle
     */
    netPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleArticle
     */
    discountPercent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleArticle
     */
    row?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleArticle
     */
    markupPercent?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PriceRuleArticle
     */
    internal?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PriceRuleArticle
     */
    showAction?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PriceRuleArticle
     */
    actionRequiered?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PriceRuleArticle
     */
    createAuto?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleArticle
     */
    lowestValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleArticle
     */
    rounding?: number | null;
}

export function PriceRuleArticleFromJSON(json: any): PriceRuleArticle {
    return PriceRuleArticleFromJSONTyped(json, false);
}

export function PriceRuleArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceRuleArticle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'priceRuleId': json['priceRuleId'],
        'article': !exists(json, 'article') ? undefined : ArticleFromJSON(json['article']),
        'employeeCategories': !exists(json, 'employeeCategories') ? undefined : (json['employeeCategories'] === null ? null : (json['employeeCategories'] as Array<any>).map(CategoryFromJSON)),
        'sellingPrice': !exists(json, 'sellingPrice') ? undefined : json['sellingPrice'],
        'netPrice': !exists(json, 'netPrice') ? undefined : json['netPrice'],
        'discountPercent': !exists(json, 'discountPercent') ? undefined : json['discountPercent'],
        'row': !exists(json, 'row') ? undefined : json['row'],
        'markupPercent': !exists(json, 'markupPercent') ? undefined : json['markupPercent'],
        'internal': !exists(json, 'internal') ? undefined : json['internal'],
        'showAction': !exists(json, 'showAction') ? undefined : json['showAction'],
        'actionRequiered': !exists(json, 'actionRequiered') ? undefined : json['actionRequiered'],
        'createAuto': !exists(json, 'createAuto') ? undefined : json['createAuto'],
        'lowestValue': !exists(json, 'lowestValue') ? undefined : json['lowestValue'],
        'rounding': !exists(json, 'rounding') ? undefined : json['rounding'],
    };
}

export function PriceRuleArticleToJSON(value?: PriceRuleArticle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'priceRuleId': value.priceRuleId,
        'article': ArticleToJSON(value.article),
        'employeeCategories': value.employeeCategories === undefined ? undefined : (value.employeeCategories === null ? null : (value.employeeCategories as Array<any>).map(CategoryToJSON)),
        'sellingPrice': value.sellingPrice,
        'netPrice': value.netPrice,
        'discountPercent': value.discountPercent,
        'row': value.row,
        'markupPercent': value.markupPercent,
        'internal': value.internal,
        'showAction': value.showAction,
        'actionRequiered': value.actionRequiered,
        'createAuto': value.createAuto,
        'lowestValue': value.lowestValue,
        'rounding': value.rounding,
    };
}


