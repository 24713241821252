/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
    ProfitCenter,
    ProfitCenterFromJSON,
    ProfitCenterFromJSONTyped,
    ProfitCenterToJSON,
    Protocol,
    ProtocolFromJSON,
    ProtocolFromJSONTyped,
    ProtocolToJSON,
    WorkOrderType,
    WorkOrderTypeFromJSON,
    WorkOrderTypeFromJSONTyped,
    WorkOrderTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InvoiceType
 */
export interface InvoiceType {
    /**
     * 
     * @type {string}
     * @memberof InvoiceType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceType
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceType
     */
    number?: string | null;
    /**
     * 
     * @type {WorkOrderType}
     * @memberof InvoiceType
     */
    workOrderType?: WorkOrderType | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceType
     */
    paymentTerms?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceType
     */
    houseWork?: boolean | null;
    /**
     * 
     * @type {ProfitCenter}
     * @memberof InvoiceType
     */
    profitCenter?: ProfitCenter | null;
    /**
     * 
     * @type {Protocol}
     * @memberof InvoiceType
     */
    protocol?: Protocol | null;
    /**
     * 
     * @type {Array<Category>}
     * @memberof InvoiceType
     */
    categories?: Array<Category> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceType
     */
    usesOrderStatusCompleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceType
     */
    usesOrderStatusAttested: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceType
     */
    usesOrderStatusReceipted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceType
     */
    usesOrderStatusExported: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceType
     */
    usesOrderStatusInvoiced: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceType
     */
    invoiceBasisIncludeRows: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceType
     */
    invoiceBasisIncludePrices: boolean;
}

export function InvoiceTypeFromJSON(json: any): InvoiceType {
    return InvoiceTypeFromJSONTyped(json, false);
}

export function InvoiceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'workOrderType': !exists(json, 'workOrderType') ? undefined : WorkOrderTypeFromJSON(json['workOrderType']),
        'paymentTerms': !exists(json, 'paymentTerms') ? undefined : json['paymentTerms'],
        'houseWork': !exists(json, 'houseWork') ? undefined : json['houseWork'],
        'profitCenter': !exists(json, 'profitCenter') ? undefined : ProfitCenterFromJSON(json['profitCenter']),
        'protocol': !exists(json, 'protocol') ? undefined : ProtocolFromJSON(json['protocol']),
        'categories': !exists(json, 'categories') ? undefined : (json['categories'] === null ? null : (json['categories'] as Array<any>).map(CategoryFromJSON)),
        'usesOrderStatusCompleted': json['usesOrderStatusCompleted'],
        'usesOrderStatusAttested': json['usesOrderStatusAttested'],
        'usesOrderStatusReceipted': json['usesOrderStatusReceipted'],
        'usesOrderStatusExported': json['usesOrderStatusExported'],
        'usesOrderStatusInvoiced': json['usesOrderStatusInvoiced'],
        'invoiceBasisIncludeRows': json['invoiceBasisIncludeRows'],
        'invoiceBasisIncludePrices': json['invoiceBasisIncludePrices'],
    };
}

export function InvoiceTypeToJSON(value?: InvoiceType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'number': value.number,
        'workOrderType': WorkOrderTypeToJSON(value.workOrderType),
        'paymentTerms': value.paymentTerms,
        'houseWork': value.houseWork,
        'profitCenter': ProfitCenterToJSON(value.profitCenter),
        'protocol': ProtocolToJSON(value.protocol),
        'categories': value.categories === undefined ? undefined : (value.categories === null ? null : (value.categories as Array<any>).map(CategoryToJSON)),
        'usesOrderStatusCompleted': value.usesOrderStatusCompleted,
        'usesOrderStatusAttested': value.usesOrderStatusAttested,
        'usesOrderStatusReceipted': value.usesOrderStatusReceipted,
        'usesOrderStatusExported': value.usesOrderStatusExported,
        'usesOrderStatusInvoiced': value.usesOrderStatusInvoiced,
        'invoiceBasisIncludeRows': value.invoiceBasisIncludeRows,
        'invoiceBasisIncludePrices': value.invoiceBasisIncludePrices,
    };
}


