


































































































import Vue from 'vue';
import {
  
  SDFData,
  SDFDataRequestError,
  sdfPagedCollectAll,
} from '../SDFData';
import FilteredSelect from './FilteredSelect.vue';

export default Vue.component('new-task', {
  components: {
    FilteredSelect,
  },
  data() {
    return {
      newTask: {
        facility: { id: null as string | null },
        workObject: { id: null as string | null },
        yourReference: '',
        yourReferencePhone: '',
        yourOrderNumber: '',
        description: '',
      },
      errors: {},
      facilityOptions: new Array<any>(),
      workObjectOptions: new Array<any>(),
      newTaskID: Number,
      error: '',
      text: '',
      files: null

    };
  },
  mounted() {
    const self = this;
    SDFData.request('users/me').then(function (response: any) {
      self.newTask.yourReference = response.name;
    });
    sdfPagedCollectAll('facilities', [])
      .then((items: any) => {
        this.facilityOptions = items.map((value: any) => ({
          value: value.id,
          text: value.customer.name + ', ' + value.name + ', ' + (value.address.city != null ? value.address.city : ''),
        }));
        this.newTask.facility.id = this.$route.params.id;
      })
      .catch((error: any) => alert(JSON.stringify(error)));
  },
  methods: {
    addNewTask() {
      const self = this;
      SDFData.request('issues', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.newTask),
      })
        .then(function (newTask: any) {
          self
            .uploadDocument(newTask.id)
            .then(() => self.$router.push('/issues/' + newTask.id));
        })
        .catch(function (error: any) {
          self.errors =
            typeof error.error === 'string'
              ? JSON.parse(error.error)
              : error.error;
        });
    },
    uploadDocument(taskID: any) {
      const files = this.$refs.files as HTMLInputElement | undefined;

      if (!files || !files.files || files.files.length <= 0) {
        return Promise.resolve();
      }
      const data = new FormData();

      for (let i = 0; i < files.files.length; i++) {
        data.append('files', files.files[i]);
      }
      return SDFData.request('issues/' + taskID + '/documents', {
        method: 'post',
        body: data,
      })
        .then()
        .catch((error: any) => (this.error = JSON.stringify(error)));
    },

    fileSelected(e: any) {
      this.files = e.target.files;
    },
    selectFacility(id: string){
      if(!id){
        this.workObjectOptions= [];
        return;
      }
       sdfPagedCollectAll('workObjects?facilityId=' + id, [])
        .then((workObjects: any) => {
          this.workObjectOptions = workObjects.map((value: any) => ({
            value: value.id,
            text: value.name + ', ' + value.number + (value.serialNumber? ', ' + value.serialNumber : ''),
          }));
          this.newTask.workObject.id = this.$route.params.id;
        })
        .catch((error: any) => alert(JSON.stringify(error)));
    },
  }
});
