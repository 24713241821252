/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RefrigerantFillOrigin {
    New = 'new',
    Reused = 'reused',
    Reclaimed = 'reclaimed'
}

export function RefrigerantFillOriginFromJSON(json: any): RefrigerantFillOrigin {
    return RefrigerantFillOriginFromJSONTyped(json, false);
}

export function RefrigerantFillOriginFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefrigerantFillOrigin {
    return json as RefrigerantFillOrigin;
}

export function RefrigerantFillOriginToJSON(value?: RefrigerantFillOrigin | null): any {
    return value as any;
}

