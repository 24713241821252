/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ArticleType {
    All = 'all',
    Resource = 'resource',
    Material = 'material'
}

export function ArticleTypeFromJSON(json: any): ArticleType {
    return ArticleTypeFromJSONTyped(json, false);
}

export function ArticleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleType {
    return json as ArticleType;
}

export function ArticleTypeToJSON(value?: ArticleType | null): any {
    return value as any;
}

