/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ContractStatus {
    NotApproved = 'notApproved',
    Approved = 'approved',
    Terminated = 'terminated'
}

export function ContractStatusFromJSON(json: any): ContractStatus {
    return ContractStatusFromJSONTyped(json, false);
}

export function ContractStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractStatus {
    return json as ContractStatus;
}

export function ContractStatusToJSON(value?: ContractStatus | null): any {
    return value as any;
}

