/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IssueStatus {
    Denied = 'denied',
    Reported = 'reported',
    Taken = 'taken',
    Ongoing = 'ongoing',
    Finished = 'finished',
    Billed = 'billed',
    Closed = 'closed',
    Unknown = 'unknown'
}

export function IssueStatusFromJSON(json: any): IssueStatus {
    return IssueStatusFromJSONTyped(json, false);
}

export function IssueStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueStatus {
    return json as IssueStatus;
}

export function IssueStatusToJSON(value?: IssueStatus | null): any {
    return value as any;
}

