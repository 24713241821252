/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
    Customer,
    CustomerFromJSON,
    CustomerFromJSONTyped,
    CustomerToJSON,
    Facility,
    FacilityFromJSON,
    FacilityFromJSONTyped,
    FacilityToJSON,
    Protocol,
    ProtocolFromJSON,
    ProtocolFromJSONTyped,
    ProtocolToJSON,
    WorkObjectComponent,
    WorkObjectComponentFromJSON,
    WorkObjectComponentFromJSONTyped,
    WorkObjectComponentToJSON,
    WorkObjectFGasProperties,
    WorkObjectFGasPropertiesFromJSON,
    WorkObjectFGasPropertiesFromJSONTyped,
    WorkObjectFGasPropertiesToJSON,
    WorkObjectTruckProperties,
    WorkObjectTruckPropertiesFromJSON,
    WorkObjectTruckPropertiesFromJSONTyped,
    WorkObjectTruckPropertiesToJSON,
    WorkObjectType,
    WorkObjectTypeFromJSON,
    WorkObjectTypeFromJSONTyped,
    WorkObjectTypeToJSON,
    WorkObjectVentilationProperties,
    WorkObjectVentilationPropertiesFromJSON,
    WorkObjectVentilationPropertiesFromJSONTyped,
    WorkObjectVentilationPropertiesToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkObject
 */
export interface WorkObject {
    /**
     * 
     * @type {string}
     * @memberof WorkObject
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WorkObject
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObject
     */
    serialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObject
     */
    name?: string | null;
    /**
     * 
     * @type {Facility}
     * @memberof WorkObject
     */
    facility?: Facility | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObject
     */
    note?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WorkObject
     */
    installationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WorkObject
     */
    warrantyDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WorkObject
     */
    inspectionDate?: Date | null;
    /**
     * 
     * @type {Protocol}
     * @memberof WorkObject
     */
    protocol?: Protocol | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObject
     */
    manufactureName?: string | null;
    /**
     * 
     * @type {Customer}
     * @memberof WorkObject
     */
    invoiceCustomer?: Customer | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObject
     */
    provides?: string | null;
    /**
     * 
     * @type {WorkObjectType}
     * @memberof WorkObject
     */
    type?: WorkObjectType | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObject
     */
    placement?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WorkObject
     */
    dismantleDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObject
     */
    typeName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkObject
     */
    active?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkObject
     */
    rentalObject?: boolean | null;
    /**
     * 
     * @type {Array<Category>}
     * @memberof WorkObject
     */
    categories?: Array<Category> | null;
    /**
     * 
     * @type {WorkObjectFGasProperties}
     * @memberof WorkObject
     */
    fGasProperties?: WorkObjectFGasProperties | null;
    /**
     * 
     * @type {WorkObjectTruckProperties}
     * @memberof WorkObject
     */
    truckProperties?: WorkObjectTruckProperties | null;
    /**
     * 
     * @type {WorkObjectVentilationProperties}
     * @memberof WorkObject
     */
    ventilationProperties?: WorkObjectVentilationProperties | null;
    /**
     * 
     * @type {Array<WorkObjectComponent>}
     * @memberof WorkObject
     */
    components?: Array<WorkObjectComponent> | null;
}

export function WorkObjectFromJSON(json: any): WorkObject {
    return WorkObjectFromJSONTyped(json, false);
}

export function WorkObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'facility': !exists(json, 'facility') ? undefined : FacilityFromJSON(json['facility']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'installationDate': !exists(json, 'installationDate') ? undefined : (json['installationDate'] === null ? null : new Date(json['installationDate'])),
        'warrantyDate': !exists(json, 'warrantyDate') ? undefined : (json['warrantyDate'] === null ? null : new Date(json['warrantyDate'])),
        'inspectionDate': !exists(json, 'inspectionDate') ? undefined : (json['inspectionDate'] === null ? null : new Date(json['inspectionDate'])),
        'protocol': !exists(json, 'protocol') ? undefined : ProtocolFromJSON(json['protocol']),
        'manufactureName': !exists(json, 'manufactureName') ? undefined : json['manufactureName'],
        'invoiceCustomer': !exists(json, 'invoiceCustomer') ? undefined : CustomerFromJSON(json['invoiceCustomer']),
        'provides': !exists(json, 'provides') ? undefined : json['provides'],
        'type': !exists(json, 'type') ? undefined : WorkObjectTypeFromJSON(json['type']),
        'placement': !exists(json, 'placement') ? undefined : json['placement'],
        'dismantleDate': !exists(json, 'dismantleDate') ? undefined : (json['dismantleDate'] === null ? null : new Date(json['dismantleDate'])),
        'typeName': !exists(json, 'typeName') ? undefined : json['typeName'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'rentalObject': !exists(json, 'rentalObject') ? undefined : json['rentalObject'],
        'categories': !exists(json, 'categories') ? undefined : (json['categories'] === null ? null : (json['categories'] as Array<any>).map(CategoryFromJSON)),
        'fGasProperties': !exists(json, 'fGasProperties') ? undefined : WorkObjectFGasPropertiesFromJSON(json['fGasProperties']),
        'truckProperties': !exists(json, 'truckProperties') ? undefined : WorkObjectTruckPropertiesFromJSON(json['truckProperties']),
        'ventilationProperties': !exists(json, 'ventilationProperties') ? undefined : WorkObjectVentilationPropertiesFromJSON(json['ventilationProperties']),
        'components': !exists(json, 'components') ? undefined : (json['components'] === null ? null : (json['components'] as Array<any>).map(WorkObjectComponentFromJSON)),
    };
}

export function WorkObjectToJSON(value?: WorkObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'serialNumber': value.serialNumber,
        'name': value.name,
        'facility': FacilityToJSON(value.facility),
        'note': value.note,
        'installationDate': value.installationDate === undefined ? undefined : (value.installationDate === null ? null : value.installationDate.toISOString()),
        'warrantyDate': value.warrantyDate === undefined ? undefined : (value.warrantyDate === null ? null : value.warrantyDate.toISOString()),
        'inspectionDate': value.inspectionDate === undefined ? undefined : (value.inspectionDate === null ? null : value.inspectionDate.toISOString()),
        'protocol': ProtocolToJSON(value.protocol),
        'manufactureName': value.manufactureName,
        'invoiceCustomer': CustomerToJSON(value.invoiceCustomer),
        'provides': value.provides,
        'type': WorkObjectTypeToJSON(value.type),
        'placement': value.placement,
        'dismantleDate': value.dismantleDate === undefined ? undefined : (value.dismantleDate === null ? null : value.dismantleDate.toISOString()),
        'typeName': value.typeName,
        'active': value.active,
        'rentalObject': value.rentalObject,
        'categories': value.categories === undefined ? undefined : (value.categories === null ? null : (value.categories as Array<any>).map(CategoryToJSON)),
        'fGasProperties': WorkObjectFGasPropertiesToJSON(value.fGasProperties),
        'truckProperties': WorkObjectTruckPropertiesToJSON(value.truckProperties),
        'ventilationProperties': WorkObjectVentilationPropertiesToJSON(value.ventilationProperties),
        'components': value.components === undefined ? undefined : (value.components === null ? null : (value.components as Array<any>).map(WorkObjectComponentToJSON)),
    };
}


