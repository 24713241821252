/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkOrderStatus {
    NotCreated = 'notCreated',
    NotPlanned = 'notPlanned',
    Planned = 'planned',
    Printed = 'printed',
    Completed = 'completed',
    Attested = 'attested',
    Receipted = 'receipted',
    Invoiced = 'invoiced',
    Exported = 'exported',
    Online = 'online',
    Cancelled = 'cancelled'
}

export function WorkOrderStatusFromJSON(json: any): WorkOrderStatus {
    return WorkOrderStatusFromJSONTyped(json, false);
}

export function WorkOrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkOrderStatus {
    return json as WorkOrderStatus;
}

export function WorkOrderStatusToJSON(value?: WorkOrderStatus | null): any {
    return value as any;
}

