/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OfferRowType {
    Header = 'header',
    Detail = 'detail',
    Project = 'project'
}

export function OfferRowTypeFromJSON(json: any): OfferRowType {
    return OfferRowTypeFromJSONTyped(json, false);
}

export function OfferRowTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferRowType {
    return json as OfferRowType;
}

export function OfferRowTypeToJSON(value?: OfferRowType | null): any {
    return value as any;
}

