/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Branch,
    BranchFromJSON,
    BranchFromJSONTyped,
    BranchToJSON,
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
    Coordinates,
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
    CostCenter,
    CostCenterFromJSON,
    CostCenterFromJSONTyped,
    CostCenterToJSON,
    Customer,
    CustomerFromJSON,
    CustomerFromJSONTyped,
    CustomerToJSON,
    Employee,
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
    FileLink,
    FileLinkFromJSON,
    FileLinkFromJSONTyped,
    FileLinkToJSON,
    Municipality,
    MunicipalityFromJSON,
    MunicipalityFromJSONTyped,
    MunicipalityToJSON,
    Person,
    PersonFromJSON,
    PersonFromJSONTyped,
    PersonToJSON,
    PriceRule,
    PriceRuleFromJSON,
    PriceRuleFromJSONTyped,
    PriceRuleToJSON,
    ProfitCenter,
    ProfitCenterFromJSON,
    ProfitCenterFromJSONTyped,
    ProfitCenterToJSON,
    WorkObject,
    WorkObjectFromJSON,
    WorkObjectFromJSONTyped,
    WorkObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface Facility
 */
export interface Facility {
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    id: string;
    /**
     * 
     * @type {Branch}
     * @memberof Facility
     */
    branch?: Branch | null;
    /**
     * 
     * @type {Customer}
     * @memberof Facility
     */
    customer: Customer;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    number?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Facility
     */
    active?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    buyerNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Facility
     */
    warning?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    warningText?: string | null;
    /**
     * 
     * @type {CostCenter}
     * @memberof Facility
     */
    costCenter?: CostCenter | null;
    /**
     * 
     * @type {PriceRule}
     * @memberof Facility
     */
    priceRule?: PriceRule | null;
    /**
     * 
     * @type {ProfitCenter}
     * @memberof Facility
     */
    profitCenter?: ProfitCenter | null;
    /**
     * 
     * @type {Person}
     * @memberof Facility
     */
    mainContact?: Person | null;
    /**
     * 
     * @type {Employee}
     * @memberof Facility
     */
    ourReference?: Employee | null;
    /**
     * 
     * @type {Employee}
     * @memberof Facility
     */
    mainWorker?: Employee | null;
    /**
     * 
     * @type {Employee}
     * @memberof Facility
     */
    secondaryWorker?: Employee | null;
    /**
     * 
     * @type {Customer}
     * @memberof Facility
     */
    invoiceCustomer?: Customer | null;
    /**
     * 
     * @type {Municipality}
     * @memberof Facility
     */
    municipality?: Municipality | null;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    propertyDesignation?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof Facility
     */
    address?: Address | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof Facility
     */
    coordinates?: Coordinates | null;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Facility
     */
    categories?: Array<Category> | null;
    /**
     * 
     * @type {Array<WorkObject>}
     * @memberof Facility
     */
    readonly workObjects?: Array<WorkObject> | null;
    /**
     * 
     * @type {Array<FileLink>}
     * @memberof Facility
     */
    readonly documents?: Array<FileLink> | null;
    /**
     * 
     * @type {Array<Person>}
     * @memberof Facility
     */
    readonly contacts?: Array<Person> | null;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    externalReferenceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    notes?: string | null;
}

export function FacilityFromJSON(json: any): Facility {
    return FacilityFromJSONTyped(json, false);
}

export function FacilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Facility {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'branch': !exists(json, 'branch') ? undefined : BranchFromJSON(json['branch']),
        'customer': CustomerFromJSON(json['customer']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'buyerNumber': !exists(json, 'buyerNumber') ? undefined : json['buyerNumber'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
        'warningText': !exists(json, 'warningText') ? undefined : json['warningText'],
        'costCenter': !exists(json, 'costCenter') ? undefined : CostCenterFromJSON(json['costCenter']),
        'priceRule': !exists(json, 'priceRule') ? undefined : PriceRuleFromJSON(json['priceRule']),
        'profitCenter': !exists(json, 'profitCenter') ? undefined : ProfitCenterFromJSON(json['profitCenter']),
        'mainContact': !exists(json, 'mainContact') ? undefined : PersonFromJSON(json['mainContact']),
        'ourReference': !exists(json, 'ourReference') ? undefined : EmployeeFromJSON(json['ourReference']),
        'mainWorker': !exists(json, 'mainWorker') ? undefined : EmployeeFromJSON(json['mainWorker']),
        'secondaryWorker': !exists(json, 'secondaryWorker') ? undefined : EmployeeFromJSON(json['secondaryWorker']),
        'invoiceCustomer': !exists(json, 'invoiceCustomer') ? undefined : CustomerFromJSON(json['invoiceCustomer']),
        'municipality': !exists(json, 'municipality') ? undefined : MunicipalityFromJSON(json['municipality']),
        'propertyDesignation': !exists(json, 'propertyDesignation') ? undefined : json['propertyDesignation'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'coordinates': !exists(json, 'coordinates') ? undefined : CoordinatesFromJSON(json['coordinates']),
        'categories': !exists(json, 'categories') ? undefined : (json['categories'] === null ? null : (json['categories'] as Array<any>).map(CategoryFromJSON)),
        'workObjects': !exists(json, 'workObjects') ? undefined : (json['workObjects'] === null ? null : (json['workObjects'] as Array<any>).map(WorkObjectFromJSON)),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(FileLinkFromJSON)),
        'contacts': !exists(json, 'contacts') ? undefined : (json['contacts'] === null ? null : (json['contacts'] as Array<any>).map(PersonFromJSON)),
        'externalReferenceNumber': !exists(json, 'externalReferenceNumber') ? undefined : json['externalReferenceNumber'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function FacilityToJSON(value?: Facility | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'branch': BranchToJSON(value.branch),
        'customer': CustomerToJSON(value.customer),
        'name': value.name,
        'number': value.number,
        'active': value.active,
        'buyerNumber': value.buyerNumber,
        'warning': value.warning,
        'warningText': value.warningText,
        'costCenter': CostCenterToJSON(value.costCenter),
        'priceRule': PriceRuleToJSON(value.priceRule),
        'profitCenter': ProfitCenterToJSON(value.profitCenter),
        'mainContact': PersonToJSON(value.mainContact),
        'ourReference': EmployeeToJSON(value.ourReference),
        'mainWorker': EmployeeToJSON(value.mainWorker),
        'secondaryWorker': EmployeeToJSON(value.secondaryWorker),
        'invoiceCustomer': CustomerToJSON(value.invoiceCustomer),
        'municipality': MunicipalityToJSON(value.municipality),
        'propertyDesignation': value.propertyDesignation,
        'address': AddressToJSON(value.address),
        'coordinates': CoordinatesToJSON(value.coordinates),
        'categories': value.categories === undefined ? undefined : (value.categories === null ? null : (value.categories as Array<any>).map(CategoryToJSON)),
        'externalReferenceNumber': value.externalReferenceNumber,
        'notes': value.notes,
    };
}


