























































































































import { Permissions, ProjectSupplierInvoice, ProjectSupplierInvoiceFromJSON } from 'sdf-common/api';
import Vue from 'vue';
import { SDFData } from '../SDFData';

export default Vue.extend({
  data(): any {
    return {
      item: null,
      error: '',
      currencyFormat: new Intl.NumberFormat('sv', {style: 'currency',currency: 'SEK', minimumFractionDigits: 0, maximumFractionDigits:0}),
      showCost: false,
      showSupplierInvoices: false,
      supplierInvoices: [] as ProjectSupplierInvoice[],
    };
  },
  watch: {
    $route(to, from) {
      this.updateItem();
    },
  },
  mounted: function () {    
    this.updateItem();
  },
  methods: {
    formatCurrency(value: number | undefined): string | undefined {
      return value && this.currencyFormat.format(value);
    },
    formatDate(value: Date | undefined): string | undefined {
      return value && value.toLocaleDateString();
    },
    updateItem() {
      const self = this;
      SDFData.request('projects/' + this.$route.params.id)
        .then(function (item: any) {
          self.item = item;
        })
        .catch(function (error: any) {
          self.item = null;
          self.error = JSON.stringify(error);
        });
      SDFData.request('users/me').then(function (response: any) {
        self.showCost = response.permissions?.indexOf(Permissions.ProjectsCostRead) >= 0;
        self.showSupplierInvoices = response.permissions?.indexOf(Permissions.ProjectsSupplierInvoicesRead) >= 0;
      })
        .then(() => {
          if (self.showSupplierInvoices) {
            SDFData.request('projects/' + this.$route.params.id + '/supplierInvoices')
              .then(function (items: any) { self.supplierInvoices = items.map((i: any) => ProjectSupplierInvoiceFromJSON(i)); })
          }
        })
        .catch(function (error: any) {
          self.item = null;
          self.error = JSON.stringify(error);
        });
    },
  },
});
