/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkOrderRowType {
    Text = 'text',
    Resource = 'resource',
    Material = 'material',
    Invoice = 'invoice'
}

export function WorkOrderRowTypeFromJSON(json: any): WorkOrderRowType {
    return WorkOrderRowTypeFromJSONTyped(json, false);
}

export function WorkOrderRowTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkOrderRowType {
    return json as WorkOrderRowType;
}

export function WorkOrderRowTypeToJSON(value?: WorkOrderRowType | null): any {
    return value as any;
}

