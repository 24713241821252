/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Article,
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkObjectFGasProperties
 */
export interface WorkObjectFGasProperties {
    /**
     * 
     * @type {boolean}
     * @memberof WorkObjectFGasProperties
     */
    yearlyReport: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectFGasProperties
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectFGasProperties
     */
    designation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectFGasProperties
     */
    amount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectFGasProperties
     */
    journalPlace?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectFGasProperties
     */
    reference?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkObjectFGasProperties
     */
    gasDetector: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkObjectFGasProperties
     */
    heremeticallySealed: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectFGasProperties
     */
    effectKilowatts?: number | null;
    /**
     * 
     * @type {Article}
     * @memberof WorkObjectFGasProperties
     */
    refrigerantArticle?: Article | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectFGasProperties
     */
    cO2Equivalent: number;
    /**
     * 
     * @type {Date}
     * @memberof WorkObjectFGasProperties
     */
    manualPreviousPeriodicControl?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WorkObjectFGasProperties
     */
    readonly nextPeriodicControl?: Date | null;
}

export function WorkObjectFGasPropertiesFromJSON(json: any): WorkObjectFGasProperties {
    return WorkObjectFGasPropertiesFromJSONTyped(json, false);
}

export function WorkObjectFGasPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkObjectFGasProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'yearlyReport': json['yearlyReport'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'designation': !exists(json, 'designation') ? undefined : json['designation'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'journalPlace': !exists(json, 'journalPlace') ? undefined : json['journalPlace'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'gasDetector': json['gasDetector'],
        'heremeticallySealed': json['heremeticallySealed'],
        'effectKilowatts': !exists(json, 'effectKilowatts') ? undefined : json['effectKilowatts'],
        'refrigerantArticle': !exists(json, 'refrigerantArticle') ? undefined : ArticleFromJSON(json['refrigerantArticle']),
        'cO2Equivalent': json['cO2Equivalent'],
        'manualPreviousPeriodicControl': !exists(json, 'manualPreviousPeriodicControl') ? undefined : (json['manualPreviousPeriodicControl'] === null ? null : new Date(json['manualPreviousPeriodicControl'])),
        'nextPeriodicControl': !exists(json, 'nextPeriodicControl') ? undefined : (json['nextPeriodicControl'] === null ? null : new Date(json['nextPeriodicControl'])),
    };
}

export function WorkObjectFGasPropertiesToJSON(value?: WorkObjectFGasProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'yearlyReport': value.yearlyReport,
        'code': value.code,
        'designation': value.designation,
        'amount': value.amount,
        'journalPlace': value.journalPlace,
        'reference': value.reference,
        'gasDetector': value.gasDetector,
        'heremeticallySealed': value.heremeticallySealed,
        'effectKilowatts': value.effectKilowatts,
        'refrigerantArticle': ArticleToJSON(value.refrigerantArticle),
        'cO2Equivalent': value.cO2Equivalent,
        'manualPreviousPeriodicControl': value.manualPreviousPeriodicControl === undefined ? undefined : (value.manualPreviousPeriodicControl === null ? null : value.manualPreviousPeriodicControl.toISOString()),
    };
}


