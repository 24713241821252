/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PurchaseOrderSortOrder {
    Number = 'number',
    Supplier = 'supplier'
}

export function PurchaseOrderSortOrderFromJSON(json: any): PurchaseOrderSortOrder {
    return PurchaseOrderSortOrderFromJSONTyped(json, false);
}

export function PurchaseOrderSortOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchaseOrderSortOrder {
    return json as PurchaseOrderSortOrder;
}

export function PurchaseOrderSortOrderToJSON(value?: PurchaseOrderSortOrder | null): any {
    return value as any;
}

