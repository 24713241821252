/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProtocolItemType,
    ProtocolItemTypeFromJSON,
    ProtocolItemTypeFromJSONTyped,
    ProtocolItemTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProtocolItem
 */
export interface ProtocolItem {
    /**
     * The unique id of the protocol item.
     * @type {string}
     * @memberof ProtocolItem
     */
    id: string;
    /**
     * The title of the protocol item.
     * @type {string}
     * @memberof ProtocolItem
     */
    title: string;
    /**
     * The title of the protocol item.
     * @type {ProtocolItemType}
     * @memberof ProtocolItem
     */
    type: ProtocolItemType;
    /**
     * Show and allow editing assessment for this protocol item.
     * @type {boolean}
     * @memberof ProtocolItem
     */
    showAssessment: boolean;
    /**
     * Show and allow editing remark for this protocol item.
     * @type {boolean}
     * @memberof ProtocolItem
     */
    showNotes: boolean;
    /**
     * Show and allow editing value for this protocol item.
     * @type {boolean}
     * @memberof ProtocolItem
     */
    showValue: boolean;
    /**
     * Indicates notes are required for this protocol item.
     * @type {boolean}
     * @memberof ProtocolItem
     */
    notesRequired: boolean;
    /**
     * Indicates a value is required for this protocol item.
     * @type {boolean}
     * @memberof ProtocolItem
     */
    valueRequired: boolean;
}

export function ProtocolItemFromJSON(json: any): ProtocolItem {
    return ProtocolItemFromJSONTyped(json, false);
}

export function ProtocolItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'type': ProtocolItemTypeFromJSON(json['type']),
        'showAssessment': json['showAssessment'],
        'showNotes': json['showNotes'],
        'showValue': json['showValue'],
        'notesRequired': json['notesRequired'],
        'valueRequired': json['valueRequired'],
    };
}

export function ProtocolItemToJSON(value?: ProtocolItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'type': ProtocolItemTypeToJSON(value.type),
        'showAssessment': value.showAssessment,
        'showNotes': value.showNotes,
        'showValue': value.showValue,
        'notesRequired': value.notesRequired,
        'valueRequired': value.valueRequired,
    };
}


