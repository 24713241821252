/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SupplierInvoiceStatus {
    Received = 'received',
    Registered = 'registered',
    Approved = 'approved',
    WaitingForPayment = 'waitingForPayment',
    PartiallyPaid = 'partiallyPaid',
    Paid = 'paid',
    Investigation = 'investigation',
    Rejected = 'rejected'
}

export function SupplierInvoiceStatusFromJSON(json: any): SupplierInvoiceStatus {
    return SupplierInvoiceStatusFromJSONTyped(json, false);
}

export function SupplierInvoiceStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierInvoiceStatus {
    return json as SupplierInvoiceStatus;
}

export function SupplierInvoiceStatusToJSON(value?: SupplierInvoiceStatus | null): any {
    return value as any;
}

