/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkOrderType {
    Service = 'service',
    Inventory = 'inventory',
    Internal = 'internal',
    CAW = 'caw'
}

export function WorkOrderTypeFromJSON(json: any): WorkOrderType {
    return WorkOrderTypeFromJSONTyped(json, false);
}

export function WorkOrderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkOrderType {
    return json as WorkOrderType;
}

export function WorkOrderTypeToJSON(value?: WorkOrderType | null): any {
    return value as any;
}

