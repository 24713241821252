/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RefrigerantType {
    CFC = 'cfc',
    HFC = 'hfc',
    HCFC = 'hcfc',
    HFO = 'hfo',
    NotRefrigerant = 'notRefrigerant',
    Other = 'other'
}

export function RefrigerantTypeFromJSON(json: any): RefrigerantType {
    return RefrigerantTypeFromJSONTyped(json, false);
}

export function RefrigerantTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefrigerantType {
    return json as RefrigerantType;
}

export function RefrigerantTypeToJSON(value?: RefrigerantType | null): any {
    return value as any;
}

