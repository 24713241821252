const testingCloud = process.env.NODE_ENV !== 'production' && false;
export const isCloud = testingCloud || (/^[^.]+\.sdf\.cloud$/i).test(window.location.hostname);

let apiUri = '';
let apiUriInitialized = false;

export async function getApiUri() {
  if (apiUriInitialized) {
    return apiUri;
  }

  let config: any = {};

  if (testingCloud) {
    config = {
      baseUri: 'http://localhost',
      port: 49649,
      path: '/45da7df4-34b1-4b34-8edc-e8d06839755e',
    }
  } else if (isCloud) {
    // TODO Use company GUID when deployed to shared location
    config = {
      baseUri: 'https://api.sdf.cloud',
      port: 443,
      path: '/' + window.location.hostname.split('.')[0],
    };
  } else {
    await fetch(process.env.BASE_URL + 'config.json')
      .then(async (response) => {
        config = await response.json();
      })
      .catch(() => {
        if (process.env.NODE_ENV === 'production') {
          config = {
            baseUri: window.location.protocol + '//' + window.location.hostname,
            port: window.location.port,
            path: '/api',
          }
        } else {
          config = {
            baseUri: 'http://localhost',
            port: 49649,
            path: '',
          }
        }
      });
  } 
  const port = [80, 443].indexOf(config.port) === -1 ? ':' + config.port : '';
  const base = config.baseUri === '*' ? window.location.protocol + '//' + window.location.hostname : config.baseUri;
  apiUri = base + port + config.path;
  apiUriInitialized = true;

  return apiUri;
}

