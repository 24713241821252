/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvoiceMethod {
    NotSpecified = 'notSpecified',
    Print = 'print',
    PrintService = 'printService',
    Email = 'email',
    Einvoice = 'einvoice'
}

export function InvoiceMethodFromJSON(json: any): InvoiceMethod {
    return InvoiceMethodFromJSONTyped(json, false);
}

export function InvoiceMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceMethod {
    return json as InvoiceMethod;
}

export function InvoiceMethodToJSON(value?: InvoiceMethod | null): any {
    return value as any;
}

