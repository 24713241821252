










import Vue from 'vue';

// Insprirerad av
// https://github.com/moreta/vue-search-select/blob/master/src/components/lib/BasicSelect.vue

export default Vue.component('filtered-select', {
    props: {
      value: {},
      placeholder: {
        type: String,
        default: (): string => { return ''; }
      },
      options: {
        type: Array as () => any[], // Hack för att TypeScript klagar annars. https://github.com/vuejs/vue/pull/6856
        default: () => { return new Array<any>(); }
      }
    },
    data() {
      return {
        filterText: '',
        filteredOptions: new Array<any>(),
        selectedOptionData: null,
        focusedOptionIndex: -1,
        showList: false
      }
    },
    watch: {
      options() {
        this.clearFilter();
      },
      value(){
        if (this.value) {
          for (let index = 0; index < this.options.length; index++) {
            const option = this.options[index];
            if (this.value == option.value) {
              this.selectedOptionData = option;
              this.filterText = option.text;
              break;
            }
          }
        } else {
          this.selectedOptionData = null;
          this.filterText = '';
        }
      },
      selectedOptionData() {
        let option: any = this.selectedOptionData;
        let value = option ? option.value : null;
        if (this.value == value) return;
        this.$emit('input', value);
      }
    },
    methods: {
      inputKeyDown(e: KeyboardEvent) {
        if (e.keyCode == 38) {
          this.showList = true;
          this.focusedOptionIndex--;
          if (this.focusedOptionIndex < -1) this.focusedOptionIndex = this.filteredOptions.length - 1;
        } else if (e.keyCode == 40) {
          this.showList = true;
          this.focusedOptionIndex++;
          if (this.focusedOptionIndex >= this.filteredOptions.length) this.focusedOptionIndex = -1;
        } else if (e.keyCode == 13) {
          if (this.filteredOptions.length == 1) {
            this.selectOption(this.filteredOptions[0]);
            e.preventDefault();
            e.stopPropagation();
          } else if (this.focusedOptionIndex >= 0 && this.focusedOptionIndex <= this.filteredOptions.length - 1) {
            this.selectOption(this.filteredOptions[this.focusedOptionIndex]);
            e.preventDefault();
            e.stopPropagation();
          }
        }
      },

      doShowList() {
        (this.$refs.filterInput as HTMLInputElement).select();

        this.showList = true;
        this.clearFilter();

        let option: any = this.selectedOptionData;
        if (option) {
          this.focusedOptionIndex = this.filteredOptions.indexOf(option);
        }
      },

      selectOption(option: any) {
        this.selectedOptionData = option;
        this.showList = false;
        this.filterText = option.text;
        let self = this;
        // setTimeout för att chrome/edge skall hänga med
        // https://stackoverflow.com/questions/17384464/jquery-focus-not-working-in-chrome
        setTimeout(function () {
          (self.$refs.filterInput as HTMLInputElement).select();
          (self.$refs.filterInput as HTMLInputElement).focus();
        }, 1);
      },

      filterOptions() {
        let filterText = this.filterText.trim();
        let words = filterText.toLowerCase().split(' ');
        let self = this;
        this.filteredOptions = this.options.filter(function (value: any) {
          if (!filterText) return true;
          let text = value.text.toLowerCase();

          for (let index = 0; index < words.length; index++) {
            if (!text.includes(words[index])) return false;
          }
          
          return true;
        });

        if (!filterText) this.selectedOptionData = null;
        this.showList = this.filteredOptions.length > 0;
      },

      doOnBlur() {
        let option: any = this.selectedOptionData;
        this.filterText = option ? option.text : '';
        this.showList = false;
      },

      clearFilter() {
        this.filteredOptions = this.options;
        if (this.filteredOptions.length == 0) this.showList = false;
      },

      clearFilterText() {
        this.filterText = '';
        this.selectedOptionData = null;
      }
    }
});
