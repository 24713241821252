












































import Vue from 'vue';
import MainMenu from './MainMenu.vue';
import { SDFData, SDFDataRequestError } from '../SDFData';
import * as config from 'sdf-common/config';
import { Entities } from 'sdf-common/api';

export default Vue.extend({
  name: 'app',
  components: {
    MainMenu,
  },
  data() {
    return {
      loggedIn: false,
      loggingIn: false,
      loginError: '',
      username: '',
      password: '',
      breadcrumbs: {},
      baseUrl: SDFData.getBaseUrl()
    }
  },
  mounted() {
    const self = this;
    config.getApiUri().then((uri) => {
      SDFData.apiUri = uri;
      SDFData.refreshAccessToken()
        .then(function () {
          self.loggedIn = true;
        }).catch(function (error: any) {
          self.loggedIn = false;
          if (error instanceof SDFDataRequestError) {
            if (error.request.status == 401) self.loginError = 'Du har blivit utloggad. Vänligen logga in igen.';
            else self.loginError = error.error;
          }
        });
      setTimeout(this.updateBreadcrumbs, 100);
    })
  },
  watch: {
    $route (to, from) {
      setTimeout(this.updateBreadcrumbs, 100);
    }
  },
  methods: {
    logOut() {
      localStorage.removeItem('token');
      this.loggedIn = false;
    },

    updateBreadcrumbs() {
      const json = JSON.parse(sessionStorage.getItem('breadcrumbs') || '{}');
      const result: any = {};
      for (const key in json) {
        if (this.$route.path.startsWith(key)) result[key] = json[key];
      }

      function arraysEqual(a: Array<any>, b: Array<any>) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length != b.length) return false;
        for (let i = 0; i < a.length; ++i) {
          if (a[i] !== b[i]) return false;
        }
        return true;
      }

      const lastArray = Object.keys(this.breadcrumbs);
      const currentArray = Object.keys(result);
      //if (!arraysEqual(lastArray, currentArray)) this.lastBreadcrumbs = this.breadcrumbs;
      if (!arraysEqual(lastArray.slice(0, currentArray.length), currentArray)) this.breadcrumbs = result;
    },

    logIn() {
      const body = new URLSearchParams();
      body.append('grant_type', 'password');
      body.append('client_id', 'sdf');
      body.append('username', this.username);
      body.append('password', this.password);
      body.append('scope', 'api offline_access');

      this.loginError = '';
      this.loggingIn = true;
      const self = this;
      fetch(SDFData.apiUri + '/connect/token', { method: 'POST', body }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            localStorage.setItem('token', JSON.stringify({
              accessToken: data.access_token,
              refreshToken: data.refresh_token,
              expires: Date.now() + data.expires_in * 1000
            }));
            self.loggedIn = true;
            self.username = '';
            self.password = '';
            SDFData.request(Entities.Branches).then(function (result) {
              localStorage.setItem('companies', JSON.stringify(result));
            }).catch(function (){
              // Do nothing
            });
            self.loggingIn = false;
          });
        } else if (response.status === 401) {
          self.loginError = 'Felaktigt användarnamn eller lösenord';
        } else {
          response.json().then((error) => {
            self.loggingIn = false;
            
            if (error.error === 'invalid_grant') {
              self.loginError = 'Felaktigt användarnamn eller lösenord';
            } else {
              self.loginError = error.error;
            }
          });
        }
      }).catch(error => {
        console.error(error);
        self.loginError = 'Okänt fel - se fellogg';
        self.loggingIn = false;
      });
    }
  }
});
