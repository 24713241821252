/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ContractRowType {
    Standard = 'standard',
    FixedFee = 'fixedFee'
}

export function ContractRowTypeFromJSON(json: any): ContractRowType {
    return ContractRowTypeFromJSONTyped(json, false);
}

export function ContractRowTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractRowType {
    return json as ContractRowType;
}

export function ContractRowTypeToJSON(value?: ContractRowType | null): any {
    return value as any;
}

