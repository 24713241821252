import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './components/App.vue';
import Issue from './components/Issue.vue';
import Issues from './components/Issues.vue';
import NewIssue from './components/NewIssue.vue';
import Facilities from './components/Facilities.vue';
import NotFound from './components/NotFound.vue';
import Map from './components/Map.vue';
import Projects from './components/Projects.vue';
import Project from './components/Project.vue';
import WasteList from './components/WasteList.vue';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister();
    }
  }).catch((err) => {
    console.log('Service Worker unregistration failed: ', err);
  });
}
Vue.use(VueRouter);

const router = new VueRouter({
  /*mode: 'history',
  scrollBehavior: function(to, from, savedPosition) {
      if (to.hash) {
          return {selector: to.hash}
      } else {
          return { x: 0, y: 0 }
      }
  },*/
  routes: [
    { path: '/', redirect: '/issues' },
    { path: '/issues', component: Issues },
    { path: '/issues/:id', component: Issues },
    { path: '/new-task/:id?', component: NewIssue },
    { path: '/facilities', component: Facilities },
    { path: '/facilities/:id', component: Facilities },
    {
      path: '/map', component: Map,
      children: [{ path: ':id', component: Issue }]
    },
    {
      path: '/projects',
      component: Projects,
      children: [{ path: '/projects/:id', component: Project }]
    },
    { path: '/waste', component: WasteList},

    { path: '*', component: NotFound }
  ]
});

new Vue({
  router: router,
  render: (h) => h(App)
}).$mount('#app');

