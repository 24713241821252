/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkObjectTruckProperties
 */
export interface WorkObjectTruckProperties {
    /**
     * 
     * @type {number}
     * @memberof WorkObjectTruckProperties
     */
    fuel?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectTruckProperties
     */
    tonnage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectTruckProperties
     */
    liftHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectTruckProperties
     */
    freeLift?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectTruckProperties
     */
    maxHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectTruckProperties
     */
    width?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectTruckProperties
     */
    length?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectTruckProperties
     */
    forkLength?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectTruckProperties
     */
    extender?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkObjectTruckProperties
     */
    weight?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkObjectTruckProperties
     */
    liftingHook: boolean;
}

export function WorkObjectTruckPropertiesFromJSON(json: any): WorkObjectTruckProperties {
    return WorkObjectTruckPropertiesFromJSONTyped(json, false);
}

export function WorkObjectTruckPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkObjectTruckProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fuel': !exists(json, 'fuel') ? undefined : json['fuel'],
        'tonnage': !exists(json, 'tonnage') ? undefined : json['tonnage'],
        'liftHeight': !exists(json, 'liftHeight') ? undefined : json['liftHeight'],
        'freeLift': !exists(json, 'freeLift') ? undefined : json['freeLift'],
        'maxHeight': !exists(json, 'maxHeight') ? undefined : json['maxHeight'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'forkLength': !exists(json, 'forkLength') ? undefined : json['forkLength'],
        'extender': !exists(json, 'extender') ? undefined : json['extender'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'liftingHook': json['liftingHook'],
    };
}

export function WorkObjectTruckPropertiesToJSON(value?: WorkObjectTruckProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fuel': value.fuel,
        'tonnage': value.tonnage,
        'liftHeight': value.liftHeight,
        'freeLift': value.freeLift,
        'maxHeight': value.maxHeight,
        'width': value.width,
        'length': value.length,
        'forkLength': value.forkLength,
        'extender': value.extender,
        'weight': value.weight,
        'liftingHook': value.liftingHook,
    };
}


