/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EmployeeSortOrder {
    Number = 'number',
    Name = 'name'
}

export function EmployeeSortOrderFromJSON(json: any): EmployeeSortOrder {
    return EmployeeSortOrderFromJSONTyped(json, false);
}

export function EmployeeSortOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeSortOrder {
    return json as EmployeeSortOrder;
}

export function EmployeeSortOrderToJSON(value?: EmployeeSortOrder | null): any {
    return value as any;
}

