





























































































































































import Vue from 'vue';
import Issue from './Issue.vue';
import FilteredSelect from './FilteredSelect.vue';
import { SDFData, TaskStatus, sdfPagedCollectAll } from '../SDFData';
import _debounce from 'lodash.debounce';

export default Vue.extend({
  components: { Issue, FilteredSelect },
  data(){
     const quickFilters = [
      { text: new Date().getFullYear() - 1, param: 'mine' },
      { text: new Date().getFullYear(), param: 'active' },
      { text: 'Alla', param: 'all' },
    ]; 
    return {

    loadSuccessful: false,
    loadError: '',
    currentTaskId: '',
    taskList: [] as any[],
    facilityOptions: [] as { value: string; text: string }[],
    nextLink: null as string | null,
    filteredTaskList: [] as any[],
    filterText: '',
    filterStatusAll: true,
    filterStatusNotTaken: false,
    filterStatusTaken: false,
    filterStatusFinished: false,
    filterStatusReported: false,
    filterThisYear: false,
    filterPreviousYear: false,
    filterAllYears: true,
    filterFacilityId: null,
    loadMoreDebounce: (() => {
      /* do nothing */
    }) as () => void,
    activeQuickFilter: quickFilters[2],
  }},
  computed: {
    filtersActive(): boolean {
      const allStatuses =
        this.filterStatusAll ||
        (this.filterStatusFinished &&
          this.filterStatusNotTaken &&
          this.filterStatusTaken);
      return (
        this.filterText != '' || !allStatuses || this.filterFacilityId != null
      );
    },
  },
  methods: {
    toggleStatusFilter(all: boolean): void {
      this.filterStatusAll = all;
      if (
        !this.filterStatusNotTaken &&
        !this.filterStatusTaken &&
        !this.filterStatusFinished
      )
        this.filterStatusAll = true;
      if (this.filterStatusAll) {
        this.filterStatusNotTaken = false;
        this.filterStatusTaken = false;
        this.filterStatusFinished = false;
      }
      _debounce(() => {
        this.updateTaskList(true);
      }, 100).call(this);
    },
    updateFacilityList(): void {
      sdfPagedCollectAll('facilities', [])
        .then((items) => {
          this.facilityOptions = items.map((value) => ({
            value: value.id,
            text: value.customer.name + ', ' + value.name + ', ' + (value.address.city != null ? value.address.city : ''),
          }));
        })
        .catch((error) => alert(JSON.stringify(error)));
    },
    getFilterParams() {
      const queryList = new Array<string>();
      queryList.push('filterText=' + encodeURIComponent(this.filterText));

      let statuses = [];
      if (this.filterStatusNotTaken)
        statuses.push(TaskStatus.Reported, TaskStatus.Denied);
      if (this.filterStatusTaken)
        statuses.push(TaskStatus.Taken, TaskStatus.Ongoing);
      if (this.filterStatusFinished)
        statuses.push(
          TaskStatus.Finished,
          TaskStatus.Billed,
          TaskStatus.Closed,
          TaskStatus.Unknown
        );
      if (this.filterStatusAll) statuses = [];

      statuses.forEach((element) => {
        queryList.push('status=' + encodeURIComponent(element.toString()));
      });

      let years = [];

      if (this.filterPreviousYear)
        years.push('year=' + (new Date().getFullYear() - 1));
      if (this.filterThisYear)
        years.push('year=' + new Date().getFullYear());
      if (this.filterAllYears) years = [];

      years.forEach((element) => {
        queryList.push(element.toString());
      });

      if (this.filterFacilityId)
        queryList.push('facilityId=' + this.filterFacilityId);
      return '?' + queryList.join('&');
    },
    updateTaskList(clear: boolean): void {
      if (!this.nextLink || clear) {
        this.nextLink = 'issues' + this.getFilterParams();
      }

      SDFData.request(this.nextLink)
        .then((json: any) => {
          this.nextLink = json.nextLink;
          if (this.nextLink && this.nextLink.startsWith('/api/'))
            this.nextLink = this.nextLink.substring(5);

          if (!this.taskList || clear) this.taskList = json.items;
          else this.taskList.push.apply(this.taskList, json.items);

          this.loadSuccessful = true;
          this.filteredTaskList = this.taskList;

          this.loadMoreDebounce();
          SDFData.setBreadCrumb(this.$route.path, 'Ärenden');
        })
        .catch((error: any) => {
          this.loadError = JSON.stringify(error);
          this.loadSuccessful = false;
        });
    },
    clearFilter(): void {
      this.filterText = '';
      this.filterFacilityId = null;
      this.toggleStatusFilter(true);
    },
    getRelativeDate(date: string): string {
      return SDFData.getRelativeDate(new Date(date));
    },
    exportToExcelFile() {
      if (confirm('Exportera till excel?')) {
        SDFData.downloadFile('issues/excel' + this.getFilterParams());
      }
    },
  },
  mounted() {
    this.updateTaskList(true);
    this.updateFacilityList();
    this.loadMoreDebounce = _debounce(function (this: any) {
      if (!this.nextLink) return;

      //let element = document.getElementById('taskList');
      //if (!element) return;
      //let rect = element.getBoundingClientRect();
      //document.title = Math.round(rect.top) + ' > ' + (window.innerHeight + window.pageYOffset);

      // https://alligator.io/vuejs/implementing-infinite-scroll/
      //let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;
      const margin = 1000;
      const bottomOfWindow =
        window.innerHeight + window.pageYOffset >=
        document.documentElement.scrollHeight - margin;
      if (!bottomOfWindow) return;

      this.updateTaskList(false);
    }, 50);
  },
  created() {
    window.addEventListener('scroll', this.loadMoreDebounce);
  },
  destroyed() {
    window.removeEventListener('scroll', this.loadMoreDebounce);
  },
  watch: {
    filterText() {
      _debounce(function (this: any) {
        this.updateTaskList(true);
      }, 500).call(this);
    },
    filterFacilityId() {
      _debounce(function (this: any) {
        this.updateTaskList(true);
      }, 100).call(this);
    },
    filterStatusAll(val) {
      if (val)
        this.$nextTick(() => {
          this.toggleStatusFilter(true);
        });
    },
    filterStatusFinished() {
      this.$nextTick(() => {
        this.toggleStatusFilter(false);
      });
    },
    filterStatusTaken() {
      this.$nextTick(() => {
        this.toggleStatusFilter(false);
      });
    },
    filterStatusNotTaken() {
      this.$nextTick(() => {
        this.toggleStatusFilter(false);
      });
    },
    filterAllYears(val) {
      if (val)
        this.$nextTick(() => {
          this.filterThisYear = false;
          this.filterPreviousYear = false;
          this.updateTaskList(true);
        })
    },
    filterThisYear(val) {
      if (val)
        this.$nextTick(() => {
          this.filterAllYears = false;
          this.filterPreviousYear = false;
          this.updateTaskList(true);
        })
    },
    filterPreviousYear(val) {
      if (val)
        this.$nextTick(() => {
          this.filterAllYears = false;
          this.filterThisYear = false;
          this.updateTaskList(true);
        })
    },
  },
});
