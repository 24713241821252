/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
    FileLink,
    FileLinkFromJSON,
    FileLinkFromJSONTyped,
    FileLinkToJSON,
} from './';

/**
 * 
 * @export
 * @interface Branch
 */
export interface Branch {
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Branch
     */
    readonly customerCounterManual: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Branch
     */
    readonly workOrderCounterManual: boolean;
    /**
     * 
     * @type {Address}
     * @memberof Branch
     */
    address: Address;
    /**
     * 
     * @type {Array<FileLink>}
     * @memberof Branch
     */
    documents?: Array<FileLink> | null;
    /**
     * 
     * @type {Country}
     * @memberof Branch
     */
    country?: Country | null;
}

export function BranchFromJSON(json: any): Branch {
    return BranchFromJSONTyped(json, false);
}

export function BranchFromJSONTyped(json: any, ignoreDiscriminator: boolean): Branch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'customerCounterManual': json['customerCounterManual'],
        'workOrderCounterManual': json['workOrderCounterManual'],
        'address': AddressFromJSON(json['address']),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(FileLinkFromJSON)),
        'country': !exists(json, 'country') ? undefined : CountryFromJSON(json['country']),
    };
}

export function BranchToJSON(value?: Branch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'address': AddressToJSON(value.address),
        'documents': value.documents === undefined ? undefined : (value.documents === null ? null : (value.documents as Array<any>).map(FileLinkToJSON)),
        'country': CountryToJSON(value.country),
    };
}


