/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EmployeeLoginStatus {
    Inactive = 'inactive',
    Active = 'active'
}

export function EmployeeLoginStatusFromJSON(json: any): EmployeeLoginStatus {
    return EmployeeLoginStatusFromJSONTyped(json, false);
}

export function EmployeeLoginStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeLoginStatus {
    return json as EmployeeLoginStatus;
}

export function EmployeeLoginStatusToJSON(value?: EmployeeLoginStatus | null): any {
    return value as any;
}

