/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Account,
    AccountFromJSON,
    AccountFromJSONTyped,
    AccountToJSON,
    ArticleDiscountGroup,
    ArticleDiscountGroupFromJSON,
    ArticleDiscountGroupFromJSONTyped,
    ArticleDiscountGroupToJSON,
    ArticleGroup,
    ArticleGroupFromJSON,
    ArticleGroupFromJSONTyped,
    ArticleGroupToJSON,
    ArticleType,
    ArticleTypeFromJSON,
    ArticleTypeFromJSONTyped,
    ArticleTypeToJSON,
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
    FileLink,
    FileLinkFromJSON,
    FileLinkFromJSONTyped,
    FileLinkToJSON,
    InventoryPlan,
    InventoryPlanFromJSON,
    InventoryPlanFromJSONTyped,
    InventoryPlanToJSON,
    Refrigerant,
    RefrigerantFromJSON,
    RefrigerantFromJSONTyped,
    RefrigerantToJSON,
    SalaryCode,
    SalaryCodeFromJSON,
    SalaryCodeFromJSONTyped,
    SalaryCodeToJSON,
    Supplier,
    SupplierFromJSON,
    SupplierFromJSONTyped,
    SupplierToJSON,
} from './';

/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    externalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    unit?: string | null;
    /**
     * 
     * @type {Account}
     * @memberof Article
     */
    account?: Account | null;
    /**
     * 
     * @type {Account}
     * @memberof Article
     */
    accountReverseTax?: Account | null;
    /**
     * 
     * @type {Account}
     * @memberof Article
     */
    accountEU?: Account | null;
    /**
     * 
     * @type {Account}
     * @memberof Article
     */
    accountExport?: Account | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    typeDesignation?: string | null;
    /**
     * 
     * @type {ArticleType}
     * @memberof Article
     */
    type?: ArticleType | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    sellingPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    grossMarkupPercent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    grossPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    grossDiscountPercent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    netPrice?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    stocked?: boolean | null;
    /**
     * 
     * @type {Supplier}
     * @memberof Article
     */
    supplier?: Supplier | null;
    /**
     * 
     * @type {ArticleGroup}
     * @memberof Article
     */
    articleGroup?: ArticleGroup | null;
    /**
     * 
     * @type {ArticleDiscountGroup}
     * @memberof Article
     */
    articleDiscountGroup?: ArticleDiscountGroup | null;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Article
     */
    categories?: Array<Category> | null;
    /**
     * 
     * @type {Array<InventoryPlan>}
     * @memberof Article
     */
    readonly inventoryPlans?: Array<InventoryPlan> | null;
    /**
     * 
     * @type {Array<FileLink>}
     * @memberof Article
     */
    readonly documents?: Array<FileLink> | null;
    /**
     * 
     * @type {SalaryCode}
     * @memberof Article
     */
    salaryCode?: SalaryCode | null;
    /**
     * 
     * @type {Refrigerant}
     * @memberof Article
     */
    refrigerant?: Refrigerant | null;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    active: boolean;
}

export function ArticleFromJSON(json: any): Article {
    return ArticleFromJSONTyped(json, false);
}

export function ArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Article {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalNumber': !exists(json, 'externalNumber') ? undefined : json['externalNumber'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'account': !exists(json, 'account') ? undefined : AccountFromJSON(json['account']),
        'accountReverseTax': !exists(json, 'accountReverseTax') ? undefined : AccountFromJSON(json['accountReverseTax']),
        'accountEU': !exists(json, 'accountEU') ? undefined : AccountFromJSON(json['accountEU']),
        'accountExport': !exists(json, 'accountExport') ? undefined : AccountFromJSON(json['accountExport']),
        'typeDesignation': !exists(json, 'typeDesignation') ? undefined : json['typeDesignation'],
        'type': !exists(json, 'type') ? undefined : ArticleTypeFromJSON(json['type']),
        'sellingPrice': !exists(json, 'sellingPrice') ? undefined : json['sellingPrice'],
        'grossMarkupPercent': !exists(json, 'grossMarkupPercent') ? undefined : json['grossMarkupPercent'],
        'grossPrice': !exists(json, 'grossPrice') ? undefined : json['grossPrice'],
        'grossDiscountPercent': !exists(json, 'grossDiscountPercent') ? undefined : json['grossDiscountPercent'],
        'netPrice': !exists(json, 'netPrice') ? undefined : json['netPrice'],
        'stocked': !exists(json, 'stocked') ? undefined : json['stocked'],
        'supplier': !exists(json, 'supplier') ? undefined : SupplierFromJSON(json['supplier']),
        'articleGroup': !exists(json, 'articleGroup') ? undefined : ArticleGroupFromJSON(json['articleGroup']),
        'articleDiscountGroup': !exists(json, 'articleDiscountGroup') ? undefined : ArticleDiscountGroupFromJSON(json['articleDiscountGroup']),
        'categories': !exists(json, 'categories') ? undefined : (json['categories'] === null ? null : (json['categories'] as Array<any>).map(CategoryFromJSON)),
        'inventoryPlans': !exists(json, 'inventoryPlans') ? undefined : (json['inventoryPlans'] === null ? null : (json['inventoryPlans'] as Array<any>).map(InventoryPlanFromJSON)),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(FileLinkFromJSON)),
        'salaryCode': !exists(json, 'salaryCode') ? undefined : SalaryCodeFromJSON(json['salaryCode']),
        'refrigerant': !exists(json, 'refrigerant') ? undefined : RefrigerantFromJSON(json['refrigerant']),
        'active': json['active'],
    };
}

export function ArticleToJSON(value?: Article | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalNumber': value.externalNumber,
        'number': value.number,
        'name': value.name,
        'unit': value.unit,
        'account': AccountToJSON(value.account),
        'accountReverseTax': AccountToJSON(value.accountReverseTax),
        'accountEU': AccountToJSON(value.accountEU),
        'accountExport': AccountToJSON(value.accountExport),
        'typeDesignation': value.typeDesignation,
        'type': ArticleTypeToJSON(value.type),
        'sellingPrice': value.sellingPrice,
        'grossMarkupPercent': value.grossMarkupPercent,
        'grossPrice': value.grossPrice,
        'grossDiscountPercent': value.grossDiscountPercent,
        'netPrice': value.netPrice,
        'stocked': value.stocked,
        'supplier': SupplierToJSON(value.supplier),
        'articleGroup': ArticleGroupToJSON(value.articleGroup),
        'articleDiscountGroup': ArticleDiscountGroupToJSON(value.articleDiscountGroup),
        'categories': value.categories === undefined ? undefined : (value.categories === null ? null : (value.categories as Array<any>).map(CategoryToJSON)),
        'salaryCode': SalaryCodeToJSON(value.salaryCode),
        'refrigerant': RefrigerantToJSON(value.refrigerant),
        'active': value.active,
    };
}


