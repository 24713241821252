/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SupplierInvoiceApprovalRowStatus {
    NotAssigned = 'notAssigned',
    Waiting = 'waiting',
    Approved = 'approved',
    Investigation = 'investigation'
}

export function SupplierInvoiceApprovalRowStatusFromJSON(json: any): SupplierInvoiceApprovalRowStatus {
    return SupplierInvoiceApprovalRowStatusFromJSONTyped(json, false);
}

export function SupplierInvoiceApprovalRowStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierInvoiceApprovalRowStatus {
    return json as SupplierInvoiceApprovalRowStatus;
}

export function SupplierInvoiceApprovalRowStatusToJSON(value?: SupplierInvoiceApprovalRowStatus | null): any {
    return value as any;
}

