/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Account,
    AccountFromJSON,
    AccountFromJSONTyped,
    AccountToJSON,
    ArticleDiscountGroup,
    ArticleDiscountGroupFromJSON,
    ArticleDiscountGroupFromJSONTyped,
    ArticleDiscountGroupToJSON,
    ArticleGroup,
    ArticleGroupFromJSON,
    ArticleGroupFromJSONTyped,
    ArticleGroupToJSON,
    ArticleType,
    ArticleTypeFromJSON,
    ArticleTypeFromJSONTyped,
    ArticleTypeToJSON,
    Customer,
    CustomerFromJSON,
    CustomerFromJSONTyped,
    CustomerToJSON,
    PriceType,
    PriceTypeFromJSON,
    PriceTypeFromJSONTyped,
    PriceTypeToJSON,
    Supplier,
    SupplierFromJSON,
    SupplierFromJSONTyped,
    SupplierToJSON,
} from './';

/**
 * 
 * @export
 * @interface PriceRuleRow
 */
export interface PriceRuleRow {
    /**
     * 
     * @type {string}
     * @memberof PriceRuleRow
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PriceRuleRow
     */
    priceRuleId: string;
    /**
     * 
     * @type {ArticleDiscountGroup}
     * @memberof PriceRuleRow
     */
    articleDiscountGroup?: ArticleDiscountGroup | null;
    /**
     * 
     * @type {Supplier}
     * @memberof PriceRuleRow
     */
    supplier?: Supplier | null;
    /**
     * 
     * @type {Customer}
     * @memberof PriceRuleRow
     */
    customer?: Customer | null;
    /**
     * 
     * @type {ArticleGroup}
     * @memberof PriceRuleRow
     */
    articleGroup?: ArticleGroup | null;
    /**
     * 
     * @type {ArticleType}
     * @memberof PriceRuleRow
     */
    articleType?: ArticleType | null;
    /**
     * 
     * @type {PriceType}
     * @memberof PriceRuleRow
     */
    priceType?: PriceType | null;
    /**
     * 
     * @type {Account}
     * @memberof PriceRuleRow
     */
    accountCost?: Account | null;
    /**
     * 
     * @type {boolean}
     * @memberof PriceRuleRow
     */
    internal?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleRow
     */
    lowestValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleRow
     */
    discountPercent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleRow
     */
    markupPercent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleRow
     */
    rounding?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleRow
     */
    prio?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleRow
     */
    priceFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRuleRow
     */
    priceTo?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriceRuleRow
     */
    externalArticleNumberFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceRuleRow
     */
    externalArticleNumberTo?: string | null;
}

export function PriceRuleRowFromJSON(json: any): PriceRuleRow {
    return PriceRuleRowFromJSONTyped(json, false);
}

export function PriceRuleRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceRuleRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'priceRuleId': json['priceRuleId'],
        'articleDiscountGroup': !exists(json, 'articleDiscountGroup') ? undefined : ArticleDiscountGroupFromJSON(json['articleDiscountGroup']),
        'supplier': !exists(json, 'supplier') ? undefined : SupplierFromJSON(json['supplier']),
        'customer': !exists(json, 'customer') ? undefined : CustomerFromJSON(json['customer']),
        'articleGroup': !exists(json, 'articleGroup') ? undefined : ArticleGroupFromJSON(json['articleGroup']),
        'articleType': !exists(json, 'articleType') ? undefined : ArticleTypeFromJSON(json['articleType']),
        'priceType': !exists(json, 'priceType') ? undefined : PriceTypeFromJSON(json['priceType']),
        'accountCost': !exists(json, 'accountCost') ? undefined : AccountFromJSON(json['accountCost']),
        'internal': !exists(json, 'internal') ? undefined : json['internal'],
        'lowestValue': !exists(json, 'lowestValue') ? undefined : json['lowestValue'],
        'discountPercent': !exists(json, 'discountPercent') ? undefined : json['discountPercent'],
        'markupPercent': !exists(json, 'markupPercent') ? undefined : json['markupPercent'],
        'rounding': !exists(json, 'rounding') ? undefined : json['rounding'],
        'prio': !exists(json, 'prio') ? undefined : json['prio'],
        'priceFrom': !exists(json, 'priceFrom') ? undefined : json['priceFrom'],
        'priceTo': !exists(json, 'priceTo') ? undefined : json['priceTo'],
        'externalArticleNumberFrom': !exists(json, 'externalArticleNumberFrom') ? undefined : json['externalArticleNumberFrom'],
        'externalArticleNumberTo': !exists(json, 'externalArticleNumberTo') ? undefined : json['externalArticleNumberTo'],
    };
}

export function PriceRuleRowToJSON(value?: PriceRuleRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'priceRuleId': value.priceRuleId,
        'articleDiscountGroup': ArticleDiscountGroupToJSON(value.articleDiscountGroup),
        'supplier': SupplierToJSON(value.supplier),
        'customer': CustomerToJSON(value.customer),
        'articleGroup': ArticleGroupToJSON(value.articleGroup),
        'articleType': ArticleTypeToJSON(value.articleType),
        'priceType': PriceTypeToJSON(value.priceType),
        'accountCost': AccountToJSON(value.accountCost),
        'internal': value.internal,
        'lowestValue': value.lowestValue,
        'discountPercent': value.discountPercent,
        'markupPercent': value.markupPercent,
        'rounding': value.rounding,
        'prio': value.prio,
        'priceFrom': value.priceFrom,
        'priceTo': value.priceTo,
        'externalArticleNumberFrom': value.externalArticleNumberFrom,
        'externalArticleNumberTo': value.externalArticleNumberTo,
    };
}


