/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SalaryCategory,
    SalaryCategoryFromJSON,
    SalaryCategoryFromJSONTyped,
    SalaryCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface SalaryCode
 */
export interface SalaryCode {
    /**
     * 
     * @type {string}
     * @memberof SalaryCode
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalaryCode
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalaryCode
     */
    code?: string | null;
    /**
     * 
     * @type {SalaryCategory}
     * @memberof SalaryCode
     */
    category: SalaryCategory;
    /**
     * 
     * @type {boolean}
     * @memberof SalaryCode
     */
    timeCode: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SalaryCode
     */
    active?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SalaryCode
     */
    compFactor?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SalaryCode
     */
    externalCode1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalaryCode
     */
    externalCode2?: string | null;
}

export function SalaryCodeFromJSON(json: any): SalaryCode {
    return SalaryCodeFromJSONTyped(json, false);
}

export function SalaryCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalaryCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'category': SalaryCategoryFromJSON(json['category']),
        'timeCode': json['timeCode'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'compFactor': !exists(json, 'compFactor') ? undefined : json['compFactor'],
        'externalCode1': !exists(json, 'externalCode1') ? undefined : json['externalCode1'],
        'externalCode2': !exists(json, 'externalCode2') ? undefined : json['externalCode2'],
    };
}

export function SalaryCodeToJSON(value?: SalaryCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'category': SalaryCategoryToJSON(value.category),
        'timeCode': value.timeCode,
        'active': value.active,
        'compFactor': value.compFactor,
        'externalCode1': value.externalCode1,
        'externalCode2': value.externalCode2,
    };
}


