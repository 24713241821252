


























































































import Vue from 'vue';
import * as atlas from 'azure-maps-control';
import {
  SDFData,
  SDFDataRequestError,
  TaskStatus,
  sdfPagedCollectAll,
} from '../SDFData';
import _debounce from 'lodash.debounce';
import { filter } from 'vue/types/umd';
import Issue from './Issue.vue';

const msClientId = '41d5beb9-764d-4d87-bf3f-bebefda8d750';
let accessToken = '';
let expiresOn = 0;

export default Vue.extend({
  data: () => ({
    map: () => null as atlas.Map | null, // Stored as a function to prevent weird map glitches
    mapWatchId: undefined as number | undefined,
    marker: undefined as atlas.HtmlMarker | undefined,
    currentPosition: null as atlas.data.Position | null,
    targetPosition: null as atlas.data.Position | null,
    navigationPath: null as atlas.data.LineString | null,
    issues: [] as Array<any>,
    facilities: [] as Array<any>,
    facilityOptions: [] as { value: string; text: string }[],
    nextLink: null as string | null,
    filteredTaskList: [] as any[],
    filterText: '',
    filterStatusAll: true,
    filterStatusOnlyFacilities: false,
    filterStatusNotTaken: false,
    filterStatusTaken: false,
    filterStatusFinished: false,
    filterThisYear: false,
    filterPreviousYear: false,
    filterAllYears: true,
    filterFacilityId: null,
    loadMoreDebounce: (() => {}) as () => void,
    loadSuccessful: false,
    loadError: '',
    posSource: new atlas.source.DataSource('main-data-source'),
    queryForIssues: false,
    popup: new atlas.Popup(),
    statuses: [] as Array<any>,
    showIssue: false,
  }),
  computed: {
    filtersActive(): boolean {
      const allStatuses =
        this.filterStatusAll ||
        (this.filterStatusFinished &&
          this.filterStatusNotTaken &&
          this.filterStatusTaken &&
          this.filterStatusOnlyFacilities);
      return (
        this.filterText != '' || !allStatuses || this.filterFacilityId != null
      );
    }
  },
  methods: {
    updateShowIssue() {
      this.showIssue = this.$route.matched.length > 1;
    },
    getLowestIssue(issues: any) {
      let orderStatuses = [] as Array<string>;
      const indexes = [] as Array<number>;
      orderStatuses = [
        'unknown',
        'denied',
        'reported',
        'taken',
        'ongoing',
        'finished',
        'billed',
        'closed',
      ];

      for (let i = 0; i < issues.length; i++) {
        for (let j = 0; j < orderStatuses.length; j++) {
          if (issues[i].status === orderStatuses[j]) {
            indexes.push(j);
          }
        }
      }
      const lowestIndex = Math.min(...indexes);
      const returnColor = orderStatuses[lowestIndex];
      return this.getIssueColor(returnColor);
    },
    getFacilityId(issue: any) {
      return issue.facility.id;
    },

    getIssuesFromFacility(facilityId: string) {
      const issuesAtSameFacility = [] as Array<any>;
      for (let i = 0; i < this.issues.length; i++) {
        if (facilityId === this.issues[i].facility.id) {
          issuesAtSameFacility.push(this.issues[i]);
        }
      }
      return issuesAtSameFacility;
    },
    goToFacilityFromSearchString() {
      this.currentPosition = null;
      this.filterStatusAll = true;
      this.getData();
    },
    goToFacility(facilityId: string) {
      let facilityObject;
      this.currentPosition = null;
      for (let i = 0; i < this.facilities.length; i++) {
        if (facilityId === this.facilities[i].id) {
          facilityObject = this.facilities[i];
        }
      }

      this.currentPosition = [
        facilityObject.coordinates.latitude,
        facilityObject.coordinates.longitude,
      ];

      const map = this.map();

      if (!map) {
        return;
      }

      this.popup.close();

      const camera: atlas.CameraOptions = {
        center: this.currentPosition,
      };
      if (camera) {
        map.setCamera({
          center: [this.currentPosition[1], this.currentPosition[0]],
          zoom: 11,
        });
      }

      _debounce(() => {
        this.filterFacilityId = null;
      }, 300).call(this);
    },
    getIssueColor(orderStatus: string) {
      switch (orderStatus) {
        case 'denied':
          return '#FF6F61';

        case 'reported':
          return 'whitesmoke';

        case 'taken':
          return '#9BB7D4';

        case 'ongoing':
          return '#9BB7D4';

        case 'finished':
          return '#88B04B';

        case 'billed':
          return '#559019';

        case 'closed':
          return 'gray';

        case 'unknown':
          return 'gray';
      }
    },
    toggleStatusFilter(all: boolean): void {
      this.filterStatusAll = all;
      if (
        !this.filterStatusNotTaken &&
        !this.filterStatusTaken &&
        !this.filterStatusFinished &&
        !this.filterStatusOnlyFacilities
      )
        this.filterStatusAll = true;

      if (this.filterStatusAll) {
        this.filterStatusNotTaken = false;
        this.filterStatusTaken = false;
        this.filterStatusFinished = false;
        this.filterStatusOnlyFacilities = false;
      }
      _debounce(() => {
        this.getData();
      }, 100).call(this);
    },
    getData() {
      const hasIssues = this.issues && this.issues.length > 0;
      this.issues = [];
      this.popup.close();
      this.posSource.clear();
      const queryList = new Array<string>();
      
      let statuses = [];
      if (this.filterStatusNotTaken)
        statuses.push(TaskStatus.Reported, TaskStatus.Denied);
      if (this.filterStatusTaken)
        statuses.push(TaskStatus.Taken, TaskStatus.Ongoing);
      if (this.filterStatusOnlyFacilities) statuses = [];
      if (this.filterStatusFinished)
        statuses.push(
          TaskStatus.Finished,
          TaskStatus.Billed,
          TaskStatus.Closed,
          TaskStatus.Unknown
        );
      if (this.filterStatusAll) statuses = [];

      this.statuses = statuses;
      if (
        this.filterStatusNotTaken ||
        this.filterStatusTaken ||
        this.filterStatusFinished
      ) {
        this.queryForIssues = false;
      } else {
        this.queryForIssues = true;
      }

      statuses.forEach((element) => {
        queryList.push('status=' + encodeURIComponent(element.toString()));
      });

      if (this.filterFacilityId) {
        queryList.push('facilityId=' + this.filterFacilityId);
      }
      let years = [];

      if (this.filterPreviousYear)
        years.push('year=' + (new Date().getFullYear() - 1));
      if (this.filterThisYear)
        years.push('year=' + new Date().getFullYear());
      if (this.filterAllYears) years = [];

      years.forEach((element) => {
        queryList.push(element.toString());
      });

      if (this.filterText.length > 0) {
        queryList.push('filterText=' + encodeURIComponent(this.filterText));
      }

      return sdfPagedCollectAll('issues?' + queryList.join('&')).then(
        (issues: any[]) => {
          if (
            this.filterStatusOnlyFacilities &&
            !this.filterStatusNotTaken &&
            !this.filterStatusTaken &&
            !this.filterStatusFinished
          ) {
            this.issues = [];
          } else {
            this.issues = issues;
          }

          sdfPagedCollectAll('facilities').then((facilities: any[]) => {
            this.facilityOptions = facilities.map((value) => ({
              value: value.id,
              text: value.customerName + ', ' + value.name + ', ' + value.area,
            }));
            this.facilities = facilities;

            let minLat = Number.POSITIVE_INFINITY;
            let minLong = Number.POSITIVE_INFINITY;
            let maxLat = Number.NEGATIVE_INFINITY;
            let maxLong = Number.NEGATIVE_INFINITY;

            const map = this.map();

            if (!map) {
              return;
            }
            map.markers.clear();

            this.posSource.add(
              facilities
                .map((facility) => ({
                  facility,
                  issuesAtSameFacility: this.getIssuesFromFacility(facility.id),
                }))
                .filter(
                  (group) =>
                    group.facility.coordinates &&
                    (group.issuesAtSameFacility.length > 0 ||
                      this.filterStatusOnlyFacilities ||
                      (this.filterStatusAll && this.filterText === ''))
                )
                .map((group) => {
                  const facility = group.facility;
                  const issuesAtSameFacility = group.issuesAtSameFacility;
                  const lat = facility.coordinates.latitude || 0;
                  const long = facility.coordinates.longitude || 0;

                  if (lat < minLat) {
                    minLat = lat;
                  }

                  if (long < minLong) {
                    minLong = long;
                  }

                  if (lat > maxLat) {
                    maxLat = lat;
                  }

                  if (long > maxLong) {
                    maxLong = long;
                  }

                  let marker: atlas.HtmlMarker;

                  if (issuesAtSameFacility.length > 0) {
                    const issueColor = this.getLowestIssue(issuesAtSameFacility);
                    let secondaryColor = '';
                    if (issueColor === 'whitesmoke') {
                      secondaryColor = 'black';
                    }

                    marker = new atlas.HtmlMarker({
                      color: issueColor,
                      secondaryColor: secondaryColor,
                      text: '!',
                      position: [long, lat],
                    });
                  } else {
                    marker = new atlas.HtmlMarker({
                      color: 'rgb(216, 99, 68)',
                      position: [long, lat],
                    });
                  }

                  map.markers.add(marker);

                  // Required for clickable markers
                  return new atlas.data.Feature(
                    new atlas.data.Point([long, lat]),
                    { color: 'marker-yellow', object: facility }
                  );
                })
            );

            if (minLat <= maxLat && minLong <= maxLong) {
              const margin = 0.1;

              //If query parameters exists with coordinates, the map vill center at the specific issue instead of using bounding boxes
              if(this.$route.query.latitude && this.$route.query.longitude){

                let lat = this.$route.query.latitude.toString();
                let long = this.$route.query.longitude.toString();
                
                const latpos = Number.parseFloat(lat) 
                const longpos = Number.parseFloat(long)
                  if (Number.isNaN(latpos) && Number.isNaN(longpos)) {
                    return 0
                  }

                map.setCamera({ duration: 500, type: 'ease', center: [longpos, latpos], zoom: 15 });
              }
              else {
                const bounds = [
                 minLong - margin,
                 minLat - margin,
                 maxLong + margin,
                 maxLat + margin,
               ];
                if (hasIssues) {
                  map.setCamera({ bounds, duration: 500, type: 'ease', });
                } else {
                  map.setCamera({ bounds });
                }
              }
            }
          });
        }
      );
    },

    followPosition(on: boolean) {
      this.currentPosition = null;
      const map = this.map();

      if (!map) {
        return;
      }

      if (this.mapWatchId !== undefined) {
        navigator.geolocation.clearWatch(this.mapWatchId);
      }

      if (this.marker) {
        map.markers.remove(this.marker);
        this.marker = undefined;
      }

      if (on) {
        this.mapWatchId = navigator.geolocation.watchPosition(
          (position) => {
            this.currentPosition = [
              position.coords.longitude,
              position.coords.latitude,
            ];
            const camera: atlas.CameraOptions = {
              center: this.currentPosition,
            };

            // In IE the heading can be NaN which corrupts MapBox
            if (
              typeof position.coords.heading === 'number' &&
              !Number.isNaN(position.coords.heading)
            ) {
              camera.bearing = position.coords.heading;
            }

            map.setCamera(camera);

            if (this.marker) {
              this.marker.setOptions({ position: this.currentPosition });
            } else {
              this.marker = new atlas.HtmlMarker({
                htmlContent: '<div class="pulse-icon"></div>',
                position: this.currentPosition,
              });
              map.markers.add(this.marker);
            }

            if (this.targetPosition) {
              this.navigate(this.targetPosition);
              this.targetPosition = null;
            }
          },
          () => {
            map.setCamera({ center: [11.9746, 57.7089] });
          },
          {
            maximumAge: 30 * 1000,
            timeout: 1 * 1000,
            enableHighAccuracy: true,
          }
        );
      } else {
        this.targetPosition = null;
        this.navigate(this.targetPosition);
      }
    },
    navigate(target: atlas.data.Position | null) {
      if (!this.currentPosition) {
        return;
      }

      if (!target) {
        const map = this.map();

        if (!map) {
          return;
        }

        const source = map.sources.getById('nav-data-source') as
          | atlas.source.DataSource
          | undefined;

        if (source) {
          source.clear();
        }

        return;
      }

      const restRoutingRequestUrl =
        'https://atlas.microsoft.com/route/directions/json?api-version=1&query={query}&routeRepresentation=polyline&travelMode=car&view=Auto';
      const requestUrl = restRoutingRequestUrl
        .replace('{subscription-key}', atlas.getSubscriptionKey())
        .replace(
          '{query}',
          this.currentPosition[1] +
            ',' +
            this.currentPosition[0] +
            ':' +
            target[1] +
            ',' +
            target[0]
        );

      fetch(requestUrl, {
        headers: {
          'x-ms-client-id': msClientId,
          Authorization: 'Bearer ' + accessToken,
        },
      })
        .then((response) => response.json())
        .then(
          (response: {
            routes: {
              legs: { points: { longitude: number; latitude: number }[] }[];
            }[];
          }) => {
            const route = response.routes[0];
            let routeCoordinates: atlas.data.Position[] = [];

            for (let legIndex = 0; legIndex < route.legs.length; legIndex++) {
              const leg = route.legs[legIndex];
              const legCoordinates = leg.points.map((point) => {
                return [point.longitude, point.latitude];
              });

              routeCoordinates = routeCoordinates.concat(legCoordinates);
            }

            const map = this.map();

            if (!map) {
              return;
            }

            const source = map.sources.getById('nav-data-source') as
              | atlas.source.DataSource
              | undefined;

            if (source) {
              source.clear();
              source.add(new atlas.data.LineString(routeCoordinates));
            }
          }
        );
    },
  },
  mounted() {
    this.updateShowIssue();
    const mapElement = this.$refs.map as HTMLElement;
    const posSource = this.posSource;
    const posLayer = new atlas.layer.SymbolLayer(posSource, undefined, {
      textOptions: {
        textField: ['get', 'title'],
        offset: [0, -1.2],
        // color: 'white',
      },
      iconOptions: {
        image: ['get', 'color'],
      },
      filter: [
        'any',
        ['==', ['geometry-type'], 'Point'],
        ['==', ['geometry-type'], 'MultiPoint'],
      ],
    });
    const navSource = new atlas.source.DataSource('nav-data-source');
    const navLayer = new atlas.layer.LineLayer(navSource, undefined, {
      strokeColor: '#2272B9',
      strokeWidth: 5,
      lineJoin: 'round',
      lineCap: 'round',
    });

    let centerPosition;
    if (this.currentPosition == null) {
      centerPosition = [11.9746, 57.7089];
    } else {
      centerPosition = this.currentPosition;
    }

    const map = new atlas.Map(mapElement, {
      center: centerPosition,
      zoom: 12,
      language: 'sv-SE',
      style: 'road', // theme.isDark() ? 'night' :
      authOptions: {
        authType: atlas.AuthenticationType.anonymous,
        clientId: msClientId,
        getToken: (resolve, reject) => {
          if (Date.now() < expiresOn) {
            resolve(accessToken);
          } else {
            SDFData.request('maps/token')
              .then((result: any) => {
                accessToken = result.accessToken;
                expiresOn = Date.now() + result.expiresIn * 1000;
                resolve(accessToken);
              })
              .catch(reject);
          }
        },
      },
    });
    mapElement.style.visibility = 'hidden';
    map.events.add('ready', () => {
      map.sources.add(posSource);
      map.sources.add(navSource);
      map.layers.add(posLayer);
      map.layers.add(navLayer, 'labels');
      this.map = () => map;
      setTimeout(() => {
        mapElement.style.visibility = '';
      }, 200);
    });

    const popup = new atlas.Popup({
      closeButton: false,
      showPointer: false,
    });

    map.events.add('click', (e) => {
      let matched = false;
      if (e.shapes && e.shapes.length > 0) {
        let position: atlas.data.Position | undefined;
        const items = [] as Array<any>;
        e.shapes.forEach((shape: any) => {
          if (shape.data) {
            shape = shape.data;
          }

          if (
            shape instanceof atlas.data.Feature &&
            shape.properties &&
            shape.geometry &&
            shape.geometry.coordinates
          ) {
            items.push((shape.properties as any).object);
            position = shape.geometry.coordinates;
          }
        });

        if (items.length > 0) {
          const div = document.createElement('div');
          div.className = 'azure-maps-popup';

          for (const facility of items) {
            if (facility.coordinates) {
              const id = facility.id;
              const issuesAtSameFacility = this.getIssuesFromFacility(id);

              if (
                (issuesAtSameFacility.length > 0 &&
                  !this.filterStatusOnlyFacilities) ||
                this.filterStatusNotTaken ||
                this.filterStatusTaken ||
                this.filterStatusFinished
              ) {
                const statusDiv = div.appendChild(
                  document.createElement('div')
                );
                statusDiv.appendChild(
                  document.createTextNode(issuesAtSameFacility[0].facility.name)
                );
                statusDiv.style.fontWeight = 'bold';

                for (let i = 0; i < issuesAtSameFacility.length; i++) {
                  const statusDiv = div.appendChild(
                    document.createElement('div')
                  );
                  const ulInDiv = statusDiv.appendChild(
                    document.createElement('ul')
                  );

                  const linkDiv = ulInDiv.appendChild(
                    document.createElement('a')
                  );
                  linkDiv.className = 'link';   

                  linkDiv.href = this.$router.resolve('/map/' + issuesAtSameFacility[i].id).href;
                  const li = linkDiv.appendChild(document.createElement('li'));
                  const spanInDiv = li.appendChild(
                    document.createElement('span')
                  );
                  spanInDiv.className =
                    'task-status task-status-' + issuesAtSameFacility[i].status;
                  spanInDiv.style.marginRight = '20px';

                  li.appendChild(
                    document.createTextNode(
                      issuesAtSameFacility[i].title +
                        ' - ' +
                        issuesAtSameFacility[i].statusText
                    )
                  );
                  li.setAttribute('style', 'display: block;');
                }
                const btnLink = div.appendChild(document.createElement('a'));
                const btn = btnLink.appendChild(
                  document.createElement('button')
                );
                btn.style.marginTop = '10px';
                btnLink.className = 'azure-maps-popup-link';
                btnLink.href =
                  '#/new-task/' + issuesAtSameFacility[0].facility.id; //Alla issues har samma facility, därav [0]
                btn.appendChild(document.createTextNode('Felanmäl'));
              } else {
                const customerDiv = div.appendChild(
                  document.createElement('div')
                );
                customerDiv.className =
                  'azure-maps-popup-status azure-maps-popup-status';
                customerDiv.appendChild(
                  document.createTextNode(facility.customerName)
                );

                const facilityDiv = div.appendChild(
                  document.createElement('div')
                );
                facilityDiv.className = 'azure-maps-popup-description';
                facilityDiv.style.fontWeight = 'bold';
                facilityDiv.appendChild(document.createTextNode(facility.name));

                const adress =
                  facility.postalAddress + ' ' + facility.customerArea;

                if (adress) {
                  const statusDiv = div.appendChild(
                    document.createElement('div')
                  );
                  statusDiv.className =
                    'azure-maps-popup-status azure-maps-popup-status-';
                  statusDiv.appendChild(document.createTextNode(adress));
                }

                const linkDiv = div.appendChild(document.createElement('a'));
                const btn = linkDiv.appendChild(
                  document.createElement('button')
                );
                btn.style.marginTop = '10px';
                linkDiv.className = 'azure-maps-popup-link';
                linkDiv.href = '#/new-task/' + facility.id;
                btn.appendChild(document.createTextNode('Felanmäl'));
              }
            }
          }
          popup.setOptions({
            content: div,
            position: position,
            pixelOffset: [0, 0],
          });
          popup.open(map);
          matched = true;
        }
      }

      if (!matched) {
        popup.close();
      }
    });
    this.popup = popup;

    _debounce(() => {
      this.getData();
    }, 200).call(this);
  },
  watch: {
    '$route.path': 'updateShowIssue',
    filterText() {
      _debounce(function (this: any) {
        this.goToFacilityFromSearchString();
      }, 500).call(this);
    },
    filterFacilityId(val) {
      if (val)
        _debounce(function (this: any) {
          this.goToFacility(val);
        }, 100).call(this);
    },
    filterStatusAll(val) {
      if (val)
        this.$nextTick(() => {
          this.toggleStatusFilter(true);
        });
    },
    filterStatusFinished() {
      this.$nextTick(() => {
        this.toggleStatusFilter(false);
      });
    },

    filterStatusOnlyFacilities() {
      this.$nextTick(() => {
        this.toggleStatusFilter(false);
      });
    },
    filterStatusTaken() {
      this.$nextTick(() => {
        this.toggleStatusFilter(false);
      });
    },
    filterStatusNotTaken() {
      this.$nextTick(() => {
        this.toggleStatusFilter(false);
      });
    },
    filterAllYears(val) {
      if (val)
        this.$nextTick(() => {
          this.filterThisYear = false;
          this.filterPreviousYear = false;
          this.getData();
        })
    },
    filterThisYear(val) {
      if (val)
        this.$nextTick(() => {
          this.filterAllYears = false;
          this.filterPreviousYear = false;
          this.getData();
        })
    },
    filterPreviousYear(val) {
      if (val)
        this.$nextTick(() => {
          this.filterAllYears = false;
          this.filterThisYear = false;
          this.getData();
        })
    },
  },
});
