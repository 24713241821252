/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Inventory,
    InventoryFromJSON,
    InventoryFromJSONTyped,
    InventoryToJSON,
    InventoryLocation,
    InventoryLocationFromJSON,
    InventoryLocationFromJSONTyped,
    InventoryLocationToJSON,
} from './';

/**
 * Planned inventory, for actual inventory use InventoryBalance
 * @export
 * @interface InventoryPlan
 */
export interface InventoryPlan {
    /**
     * 
     * @type {Inventory}
     * @memberof InventoryPlan
     */
    inventory?: Inventory | null;
    /**
     * 
     * @type {InventoryLocation}
     * @memberof InventoryPlan
     */
    location?: InventoryLocation | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryPlan
     */
    orderPoint?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryPlan
     */
    orderQuantity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryPlan
     */
    readonly balance?: number | null;
}

export function InventoryPlanFromJSON(json: any): InventoryPlan {
    return InventoryPlanFromJSONTyped(json, false);
}

export function InventoryPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventory': !exists(json, 'inventory') ? undefined : InventoryFromJSON(json['inventory']),
        'location': !exists(json, 'location') ? undefined : InventoryLocationFromJSON(json['location']),
        'orderPoint': !exists(json, 'orderPoint') ? undefined : json['orderPoint'],
        'orderQuantity': !exists(json, 'orderQuantity') ? undefined : json['orderQuantity'],
        'balance': !exists(json, 'balance') ? undefined : json['balance'],
    };
}

export function InventoryPlanToJSON(value?: InventoryPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventory': InventoryToJSON(value.inventory),
        'location': InventoryLocationToJSON(value.location),
        'orderPoint': value.orderPoint,
        'orderQuantity': value.orderQuantity,
    };
}


