/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PriceRuleArticle,
    PriceRuleArticleFromJSON,
    PriceRuleArticleFromJSONTyped,
    PriceRuleArticleToJSON,
    PriceRuleRow,
    PriceRuleRowFromJSON,
    PriceRuleRowFromJSONTyped,
    PriceRuleRowToJSON,
    PriceType,
    PriceTypeFromJSON,
    PriceTypeFromJSONTyped,
    PriceTypeToJSON,
    Supplier,
    SupplierFromJSON,
    SupplierFromJSONTyped,
    SupplierToJSON,
} from './';

/**
 * 
 * @export
 * @interface PriceRule
 */
export interface PriceRule {
    /**
     * 
     * @type {string}
     * @memberof PriceRule
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PriceRule
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof PriceRule
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PriceRule
     */
    description: string;
    /**
     * 
     * @type {Supplier}
     * @memberof PriceRule
     */
    supplier?: Supplier | null;
    /**
     * 
     * @type {PriceType}
     * @memberof PriceRule
     */
    readonly materialPriceType?: PriceType | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRule
     */
    readonly materialDiscount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRule
     */
    readonly materialMarkup?: number | null;
    /**
     * 
     * @type {PriceType}
     * @memberof PriceRule
     */
    readonly resourcePriceType?: PriceType | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRule
     */
    readonly resourceDiscount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceRule
     */
    readonly resourceMarkup?: number | null;
    /**
     * 
     * @type {Array<PriceRuleRow>}
     * @memberof PriceRule
     */
    readonly rows?: Array<PriceRuleRow> | null;
    /**
     * 
     * @type {Array<PriceRuleArticle>}
     * @memberof PriceRule
     */
    readonly articles?: Array<PriceRuleArticle> | null;
}

export function PriceRuleFromJSON(json: any): PriceRule {
    return PriceRuleFromJSONTyped(json, false);
}

export function PriceRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'name': json['name'],
        'description': json['description'],
        'supplier': !exists(json, 'supplier') ? undefined : SupplierFromJSON(json['supplier']),
        'materialPriceType': !exists(json, 'materialPriceType') ? undefined : PriceTypeFromJSON(json['materialPriceType']),
        'materialDiscount': !exists(json, 'materialDiscount') ? undefined : json['materialDiscount'],
        'materialMarkup': !exists(json, 'materialMarkup') ? undefined : json['materialMarkup'],
        'resourcePriceType': !exists(json, 'resourcePriceType') ? undefined : PriceTypeFromJSON(json['resourcePriceType']),
        'resourceDiscount': !exists(json, 'resourceDiscount') ? undefined : json['resourceDiscount'],
        'resourceMarkup': !exists(json, 'resourceMarkup') ? undefined : json['resourceMarkup'],
        'rows': !exists(json, 'rows') ? undefined : (json['rows'] === null ? null : (json['rows'] as Array<any>).map(PriceRuleRowFromJSON)),
        'articles': !exists(json, 'articles') ? undefined : (json['articles'] === null ? null : (json['articles'] as Array<any>).map(PriceRuleArticleFromJSON)),
    };
}

export function PriceRuleToJSON(value?: PriceRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'name': value.name,
        'description': value.description,
        'supplier': SupplierToJSON(value.supplier),
    };
}


