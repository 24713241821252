/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VentilationSystem,
    VentilationSystemFromJSON,
    VentilationSystemFromJSONTyped,
    VentilationSystemToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkObjectVentilationProperties
 */
export interface WorkObjectVentilationProperties {
    /**
     * 
     * @type {string}
     * @memberof WorkObjectVentilationProperties
     */
    runtimeFull?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectVentilationProperties
     */
    runtimePartTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectVentilationProperties
     */
    fanType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectVentilationProperties
     */
    projectedFlow?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkObjectVentilationProperties
     */
    calculatedFlow?: string | null;
    /**
     * 
     * @type {VentilationSystem}
     * @memberof WorkObjectVentilationProperties
     */
    systemType?: VentilationSystem | null;
}

export function WorkObjectVentilationPropertiesFromJSON(json: any): WorkObjectVentilationProperties {
    return WorkObjectVentilationPropertiesFromJSONTyped(json, false);
}

export function WorkObjectVentilationPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkObjectVentilationProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'runtimeFull': !exists(json, 'runtimeFull') ? undefined : json['runtimeFull'],
        'runtimePartTime': !exists(json, 'runtimePartTime') ? undefined : json['runtimePartTime'],
        'fanType': !exists(json, 'fanType') ? undefined : json['fanType'],
        'projectedFlow': !exists(json, 'projectedFlow') ? undefined : json['projectedFlow'],
        'calculatedFlow': !exists(json, 'calculatedFlow') ? undefined : json['calculatedFlow'],
        'systemType': !exists(json, 'systemType') ? undefined : VentilationSystemFromJSON(json['systemType']),
    };
}

export function WorkObjectVentilationPropertiesToJSON(value?: WorkObjectVentilationProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'runtimeFull': value.runtimeFull,
        'runtimePartTime': value.runtimePartTime,
        'fanType': value.fanType,
        'projectedFlow': value.projectedFlow,
        'calculatedFlow': value.calculatedFlow,
        'systemType': VentilationSystemToJSON(value.systemType),
    };
}


