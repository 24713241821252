/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IssueStatusDb {
    Reported = 'reported',
    Denied = 'denied'
}

export function IssueStatusDbFromJSON(json: any): IssueStatusDb {
    return IssueStatusDbFromJSONTyped(json, false);
}

export function IssueStatusDbFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueStatusDb {
    return json as IssueStatusDb;
}

export function IssueStatusDbToJSON(value?: IssueStatusDb | null): any {
    return value as any;
}

