


































































































import Vue from 'vue';
import { SDFData } from '../SDFData';

export default Vue.component('facility', {
    data(): any {
        return {
            item: null, 
            error: '',
            protocols: null,
        }
    },
    watch: {
      $route (to, from){
        this.updateItem();
      }
    },
    mounted: function () {
      this.updateItem();
    },
    methods: {
      updateItem() {
        const self = this;
        SDFData.request('facilities/' + this.$route.params.id)
            .then(function (item: any) {
              self.item = item;
              SDFData.setBreadCrumb(self.$route.path, self.item.name);
              SDFData.CurrentState.facilityId = self.item.id;
            })
            .catch(function (error: any) {
              self.item = null;
              self.error = JSON.stringify(error);
            });
        SDFData.request('facilities/' + this.$route.params.id + '/protocol')
          .then(function (protocols: any) {
            self.protocols = protocols;
          })
          .catch(function (error: any) {
            self.protocols = null;
            self.error = JSON.stringify(error);
          });
      },
      openFile(path: string) {
          SDFData.downloadFile(path)
              .catch(function (error: any) {
                  alert(JSON.stringify(error));
              });
      },
      getRelativeDate(date: string) {
          return SDFData.getRelativeDate(new Date(date));
      },
      getFontAwesomeFromExt(ext: string) {
        const className = 'fas fa-';
        ext = ext.replace('pdf', 'file-pdf');
        ext = ext.replace('xlsx', 'table');
        ext = ext.replace('xls', 'table');
        ext = ext.replace('png', 'image');
        ext = ext.replace('jpeg', 'image');
        ext = ext.replace('jpg', 'image');
        ext = ext.replace('bmp', 'image');
        ext = ext.replace('gif', 'image');
        ext = ext.replace('docx', 'file-word');
        ext = ext.replace('doc', 'file-word');
        return className + ext;
      }
    }
});
