/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportName {
    InvoiceBasis = 'invoiceBasis',
    Protocols = 'protocols',
    ProtocolUnaccepted = 'protocolUnaccepted'
}

export function ReportNameFromJSON(json: any): ReportName {
    return ReportNameFromJSONTyped(json, false);
}

export function ReportNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportName {
    return json as ReportName;
}

export function ReportNameToJSON(value?: ReportName | null): any {
    return value as any;
}

