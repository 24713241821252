export function sdfPagedCollectAll(url: string, a?: any[]): Promise<any[]> {
    if (url.startsWith('/')) {
        url = url.substr(1);
    }

    return SDFData.request(url)
        .then(function (response: any) {
            const items = (a || []).concat(response.items as any[]);

            if (response.nextLink) {
                return sdfPagedCollectAll(response.nextLink, items);
            } else {
                return Promise.resolve(items);
            }
        });
}

export enum TaskStatus
{
    Denied = 'denied',
    Reported = 'reported',
    Taken = 'taken',
    Ongoing = 'ongoing',
    Finished = 'finished',
    Billed = 'billed',
    Closed = 'closed',
    Unknown = 'unknown'
}

export enum OrderStatus
{
    NotCreated = 'notCreated',
    NotPlanned = 'notPlanned',
    Planned = 'planned',
    Printed = 'printed',
    Completed = 'completed',
    Receipted = 'receipted',
    Invoiced = 'invoiced',
    Exported = 'exported',
    Attested = 'attested',
    Online = 'online',
    Cancelled = 'cancelled'
}

export interface RequestResult {
    items: any;
    totalCount: number;
}

export interface Meta {
  links: { [name: string]: { [key: string]: string } };
}

function parseHeader(header: string) {
  return header.split(',').map((part) => parseHeaderPart(part.trim()));
}

function parseHeaderPart(headerPart: string) {
  const parts = headerPart.split(';');
  const main = ((parts.length > 0) && parts[0]) || '';
  const result = { main, extra: {} as { [key: string]: string } };

  for (let i = 1; i < parts.length; i++) {
    const part = parts[i];
    const sep = part.indexOf('=');

    if (sep !== -1) {
      const k = part.substring(0, sep).trim();
      const v = part.substring(sep + 1);
      result.extra[k] = v;
    }
  }

  return result;
}

export class SDFDataRequestError {
    public request: XMLHttpRequest;
    public error: string;

    constructor(xhttp: XMLHttpRequest) {
        this.request = xhttp;

        let contentType = xhttp.getResponseHeader('Content-Type');
        contentType = contentType ? contentType : '';
        const json = contentType.startsWith('application/json') ? JSON.parse(xhttp.responseText) : null;

        if (json && json.error) this.error = json.error;
        else if (json) this.error = json;
        else if (xhttp.status == 0 && !xhttp.responseText) this.error = 'Kundportalen kan inte nås. Vänligen kontrollera din internetåtkomst.';
        else if (xhttp.responseText) this.error = xhttp.responseText;
        else this.error = 'Fel vid hämtning av data. HTTP-status: ' + this.request.status;
    }
}

export class SDFData {

    public static apiUri = '';

    static CurrentState = {
        facilityId: null,
    }

    static getBaseUrl() {
        return window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/') + 1);
    }

    static getStatusText(status: string) {
        switch (status) {
            case OrderStatus.NotCreated:    return 'Ej skapad';
            case OrderStatus.NotPlanned:    return 'Ej planerad';
            case OrderStatus.Planned:       return 'Planerad';
            case OrderStatus.Printed:       return 'Utskriven';
            case OrderStatus.Completed:     return 'Åtgärdad';
            case OrderStatus.Receipted:     return 'Kvitterad';
            case OrderStatus.Invoiced:      return 'Fakturerad';
            case OrderStatus.Exported:      return 'Exporterad';
            case OrderStatus.Attested:      return 'Attesterad';
            case OrderStatus.Online:        return 'Aktiv Online';
            case OrderStatus.Cancelled:     return 'Makulerad';
            default: return 'Okänd';
        }
    }

    static getDateStr(date: Date) {
        if (!date) {
            return;
        }
        
        let dateStr = date.toLocaleDateString();
        let hours = date.getHours().toString().padStart(2, '0');
        let minutes = date.getMinutes().toString().padStart(2, '0');

        let fullDate = dateStr + ' ' + hours + ':' + minutes

        return fullDate;
    }

    static getRelativeDate(date: Date) {
        if (date.getTime() == 0) return '';

        const delta = Math.round((+new Date().getTime() - date.getTime()) / 1000);
        const minute = 60,
        hour = minute * 60,
        day = hour * 24,
        week = day * 7;

        let fuzzy = date.toLocaleDateString();// + ' ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
        if (delta < 30) {
            fuzzy = 'nu';
        } else if (delta < minute) {
            fuzzy = delta + ' sekunder sedan';
        } else if (delta < 2 * minute) {
            fuzzy = 'en minut sedan'
        } else if (delta < hour) {
            fuzzy = Math.floor(delta / minute) + ' minuter sedan';
        } else if (Math.floor(delta / hour) == 1) {
            fuzzy = '1 timme sedan'
        } else if (delta < day) {
            fuzzy = Math.floor(delta / hour) + ' timmar sedan';
        } else if (delta < day * 2) {
            fuzzy = 'igår';
        } else if (delta < day * 15) {
            fuzzy = Math.floor(delta / day) + ' dagar sedan';
        } else if (delta < week * 4) {
            fuzzy = Math.floor(delta / week) + ' veckor sedan';
        } else if (delta < week * 4) {
            fuzzy = Math.floor(delta / week) + ' veckor sedan';
        }
        return fuzzy;
    }

    static localStorageAsJson(item: string) {
        const data = localStorage.getItem(item);
        return data ? JSON.parse(data) : {};
    }

    static setBreadCrumb(path: string, title: string) {
        const breadcrumbs = sessionStorage.getItem('breadcrumbs');
        let json: any = { };
        if (breadcrumbs) json = JSON.parse(breadcrumbs);
        json[path] = title;
        sessionStorage.setItem('breadcrumbs', JSON.stringify(json));
    }

    static internalRequest(resource: string, config?: any, withMeta?: boolean) {
        return new Promise(function (resolve, reject) {
            const token = JSON.parse(localStorage.getItem('token') || '{}');
            if (config == null) config = {};
            if (!config.method) config.method = 'GET';
            if (!config.headers) config.headers = { };
            if (!config.headers['Authorization'] && token.accessToken) config.headers['Authorization'] = 'Bearer ' + token.accessToken;
            if (!config.responseType) config.responseType = '';

            const xhttp = new XMLHttpRequest();
            xhttp.onload = function() {
                let contentType = this.getResponseHeader('Content-Type');
                contentType = contentType ? contentType : '';
                const isJSON = contentType.startsWith('application/json');
                const meta: Meta = { links: {} };
                const link = this.getResponseHeader('Link');
                
                if (link) {
                    parseHeader(link).forEach((value) => { meta.links[value.main] = value.extra; });
                }

                if (this.status >= 200 && this.status < 300) {
                    if (withMeta) {
                        if (isJSON) resolve({ data: JSON.parse(this.responseText), meta });
                        else resolve({ data: this.responseText, meta });
                    } else {
                        if (this.responseType == 'arraybuffer') {
                            resolve(new Blob([this.response], { type: contentType }));
                        }
                        else if (isJSON) resolve(JSON.parse(this.responseText));
                        else resolve(this.responseText);
                    }
                } else {
                    reject(new SDFDataRequestError(xhttp));
                }
            };
            xhttp.onerror = function() {
                reject(new SDFDataRequestError(this));
            };
            xhttp.ontimeout = function () {
                reject(new SDFDataRequestError(this));
            };

            const url = SDFData.apiUri + (resource.startsWith('/') ? '' : '/') + resource;
            xhttp.open(config.method, url, true);
            xhttp.responseType = config.responseType;
            if (config.headers != null) {
                for (const key in config.headers) {
                    if (key == 'Authorization' && config.headers[key] == '') continue;
                    xhttp.setRequestHeader(key, config.headers[key]);
                }
            }
            xhttp.send(config.body);
        });
    }

    static refreshAccessToken() {
        return new Promise(function (resolve, reject) {
            const token = JSON.parse(localStorage.getItem('token') || '{}');
            const body = new URLSearchParams();
            body.append('grant_type', 'refresh_token');
            body.append('refresh_token', token.refreshToken);
            body.append('client_id', 'sdf');

            fetch(SDFData.apiUri + '/connect/token', { method: 'POST', body })
                .then(response => (response.ok) ? response.json() : Promise.reject('Failed to refresh access token'))
                .then((data) => {
                    const token = {
                        accessToken: data.access_token,
                        refreshToken: data.refresh_token,
                        expires: Date.now() + data.expires_in * 1000
                    };
                    localStorage.setItem('token', JSON.stringify(token));
                    resolve(token);
                })
                .catch(reject);
        });
    }

    private static requestBase(resource: string, config: any, withMeta: boolean) {
        return new Promise(function (resolve, reject) {
            const expiryMarginMilliSeconds = 5000;
            const token = JSON.parse(localStorage.getItem('token') || '{}');

            if (token.expires && token.expires - expiryMarginMilliSeconds <= new Date().getTime()) {
                SDFData.refreshAccessToken()
                    .then(function () {
                        SDFData.internalRequest(resource, config, withMeta)
                            .then(resolve)
                            .catch(reject);
                    })
                    .catch(reject);
            } else {
                SDFData.internalRequest(resource, config, withMeta)
                    .then(resolve)
                    .catch(reject)
            }
        })
    }

    static request(resource: string, config?: any) {
        return SDFData.requestBase(resource, config, false)
    }

    static requestWithMeta(resource: string, config?: any) {
        return SDFData.requestBase(resource, config, true)
    }

    static downloadFile(resource: string) {
        return new Promise(function () {
            const token = JSON.parse(localStorage.getItem('token') || '{}');

            if (resource.startsWith('/')) {
                resource = resource.substr(1);
            }

            window.open(SDFData.apiUri + '/' + resource + (resource.indexOf('?') >= 0 ? '&' : '?') + 'accessToken=' + token.accessToken, '_blank');
        });
    }
}