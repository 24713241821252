





















































import Vue from 'vue';
import { SDFData } from '../SDFData';
import { Permissions } from 'sdf-common/api';

export default Vue.extend({
  name: 'MainMenu',
  data() {
    return {
      baseUrl: SDFData.getBaseUrl(),
      showWaste: false,
    }
  },
  mounted() {
    const self = this;
    SDFData.request('users/me').then(function (response: any) {
      if (response.permissions?.indexOf(Permissions.WasteObjectsRead) !== -1) {
        self.showWaste = true;
      }
    })
  },
  methods: {
    closeHamburgerMenu() {
      const element = document.getElementById('newTaskSmall');
      if (element && window.getComputedStyle(element).getPropertyValue('display') != 'none') {
        const menu: any = document.querySelector('#mainMenu ul')!;
        menu.style.height = 0 + 'px';
        menu.classList.remove('visible');
      }
    },
    toggleHamburgerMenu(event: any) {
      event.preventDefault();
      const menu: any = document.querySelector('#mainMenu ul')!;
      const scrollHeight = menu.scrollHeight;
      if (menu.style.height != scrollHeight + 'px') {
          menu.style.height = scrollHeight + 'px';
          menu.classList.add('visible');
      } else {
          menu.style.height = 0 + 'px';
          menu.classList.remove('visible');
      }
    },
    goTo(url: string) {
      this.closeHamburgerMenu();
      if (url == '/') url = '/issues';
      if (this.$router.currentRoute.path.startsWith(url)) this.$router.go(-1);
      else this.$router.push(url);
    },
    logOut() {
      this.$emit('update:logout', '');
    }
  }
});
