/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProjectStatus {
    Active = 'active',
    Finished = 'finished',
    NotStarted = 'notStarted',
    Completed = 'completed'
}

export function ProjectStatusFromJSON(json: any): ProjectStatus {
    return ProjectStatusFromJSONTyped(json, false);
}

export function ProjectStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectStatus {
    return json as ProjectStatus;
}

export function ProjectStatusToJSON(value?: ProjectStatus | null): any {
    return value as any;
}

