/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IssuePriority {
    High = 'high',
    Low = 'low'
}

export function IssuePriorityFromJSON(json: any): IssuePriority {
    return IssuePriorityFromJSONTyped(json, false);
}

export function IssuePriorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssuePriority {
    return json as IssuePriority;
}

export function IssuePriorityToJSON(value?: IssuePriority | null): any {
    return value as any;
}

