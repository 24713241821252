/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Permissions {
    WorkOrdersRead = 'WorkOrders.Read',
    WorkOrdersReadAll = 'WorkOrders.Read.All',
    WorkOrdersWriteAll = 'WorkOrders.Write.All',
    WorkOrdersReadActive = 'WorkOrders.Read.Active',
    WorkOrdersWriteActive = 'WorkOrders.Write.Active',
    WorkOrdersWrite = 'WorkOrders.Write',
    WorkOrdersCreate = 'WorkOrders.Create',
    WorkOrdersCostRead = 'WorkOrders.CostRead',
    WorkOrdersPriceRead = 'WorkOrders.PriceRead',
    WorkOrdersProtocolsDelete = 'WorkOrders.ProtocolsDelete',
    WorkOrdersChangeMainWorker = 'WorkOrders.ChangeMainWorker',
    FacilitiesDesignationUse = 'FacilitiesDesignation.Use',
    FacilitiesInternalNumberUse = 'FacilitiesInternalNumber.Use',
    FacilitiesRead = 'Facilities.Read',
    FacilitiesWrite = 'Facilities.Write',
    FacilitiesCreate = 'Facilities.Create',
    IssuesRead = 'Issues.Read',
    IssuesCreate = 'Issues.Create',
    IssuesInvoiceRead = 'Issues.InvoiceRead',
    WorkObjectsUse = 'WorkObjects.Use',
    WorkObjectsRentalUse = 'WorkObjectsRental.Use',
    WorkObjectsRead = 'WorkObjects.Read',
    WorkObjectsWrite = 'WorkObjects.Write',
    WorkObjectsCreate = 'WorkObjects.Create',
    WorkOrderRowsWrite = 'WorkOrderRows.Write',
    WorkOrderRowsOthersInternalRead = 'WorkOrderRows.OthersInternalRead',
    WorkOrderRowsOthersMaterialWrite = 'WorkOrderRows.OthersMaterialWrite',
    WorkOrderRowsDateWrite = 'WorkOrderRows.RowDateWrite',
    WorkOrderRowsUserWrite = 'WorkOrderRows.RowUserWrite',
    ProjectsUse = 'Projects.Use',
    ProjectsRead = 'Projects.Read',
    ProjectsWrite = 'Projects.Write',
    ProjectsCreate = 'Projects.Create',
    ProjectsCostRead = 'Projects.CostRead',
    ProjectsSupplierInvoicesRead = 'Projects.SupplierInvoicesRead',
    BranchesRead = 'Branches.Read',
    BranchesWrite = 'Branches.Write',
    CustomersInternationalUse = 'CustomersInternational.Use',
    CustomersHouseWorkUse = 'CustomersHouseWork.Use',
    CustomersReverseTaxUse = 'CustomersReverseTax.Use',
    CustomersPrivateUse = 'CustomersPrivate.Use',
    CustomersCreate = 'Customers.Create',
    CustomersRead = 'Customers.Read',
    CustomersWrite = 'Customers.Write',
    CustomersCompanySearch = 'Customers.CompanySearch',
    WorkOrderPlansUse = 'WorkOrderPlans.Use',
    WorkOrderPlansCreate = 'WorkOrderPlans.Create',
    WorkOrderPlansRead = 'WorkOrderPlans.Read',
    WorkOrderPlansWrite = 'WorkOrderPlans.Write',
    DocumentsInternalRead = 'Documents.InternalRead',
    DocumentsInternalWrite = 'Documents.InternalWrite',
    DocumentsHiddenOnlineRead = 'Documents.HiddenOnlineRead',
    DocumentsHiddenOnlineWrite = 'Documents.HiddenOnlineWrite',
    InstructionManualsReadWrite = 'InstructionManuals.ReadWrite',
    CompanyQualityManualsReadWrite = 'CompanyQualityManuals.ReadWrite',
    ProjectQualityManualsReadWrite = 'ProjectQualityManuals.ReadWrite',
    ArticlesRead = 'Articles.Read',
    ArticlesWrite = 'Articles.Write',
    ArticlesCreate = 'Articles.Create',
    WasteObjectsUse = 'WasteObjects.Use',
    WasteObjectsRead = 'WasteObjects.Read',
    ContractsUse = 'Contracts.Use',
    ContractsRead = 'Contracts.Read',
    ContractsWrite = 'Contracts.Write',
    ContractsCreate = 'Contracts.Create',
    EmployeesRead = 'Employees.Read',
    OffersRead = 'Offers.Read',
    OffersReadAll = 'Offers.Read.All',
    OffersWrite = 'Offers.Write',
    OffersCreate = 'Offers.Create',
    PurchaseOrdersRead = 'PurchaseOrders.Read',
    PurchaseOrdersCreate = 'PurchaseOrders.Create',
    SuppliersCreate = 'Suppliers.Create',
    SuppliersRead = 'Suppliers.Read',
    SuppliersWrite = 'Suppliers.Write',
    SupplierInvoicesCreate = 'SupplierInvoices.Create',
    SupplierInvoicesRead = 'SupplierInvoices.Read',
    SupplierInvoicesWrite = 'SupplierInvoices.Write',
    PersonsCreate = 'Persons.Create',
    PersonsRead = 'Persons.Read',
    PersonsWrite = 'Persons.Write',
    ActivitiesRead = 'Activities.Read',
    ActivitiesWrite = 'Activities.Write',
    EquipmentRead = 'Equipment.Read',
    EquipmentWrite = 'Equipment.Write',
    VehiclesTripCreate = 'Vehicles.Trip.Read',
    VehiclesRead = 'Vehicles.Read',
    VehiclesWrite = 'Vehicles.Write',
    SettingsRead = 'Settings.Read',
    SettingsWrite = 'Settings.Write',
    FGasReadWrite = 'FGas.ReadWrite',
    FGasUsageRead = 'FGas.UsageRead',
    StatisticsRead = 'Statistics.Read',
    InventoriesRead = 'Inventories.Read',
    ProtocolsUse = 'Protocols.Use',
    ProtocolsRead = 'Protocols.Read',
    CategoriesUse = 'Categories.Use',
    CRMUse = 'CRM.Use',
    ProfitCentersUse = 'ProfitCenters.Use',
    CostCentersUse = 'CostCenters.Use',
    InvoiceTypesUse = 'InvoiceTypes.Use',
    MeeterValueUse = 'MeeterValue.Use',
    CustomerRulesUse = 'CustomerRules.Use'
}

export function PermissionsFromJSON(json: any): Permissions {
    return PermissionsFromJSONTyped(json, false);
}

export function PermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permissions {
    return json as Permissions;
}

export function PermissionsToJSON(value?: Permissions | null): any {
    return value as any;
}

