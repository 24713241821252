/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RefrigerantOperationType {
    Installation = 'installation',
    Decommission = 'decommission',
    Conversion = 'conversion',
    Service = 'service',
    Periodic = 'periodic',
    FollowUpLeakDetection = 'followUpLeakDetection',
    PeriodicAndFollowUpLeakDetection = 'periodicAndFollowUpLeakDetection'
}

export function RefrigerantOperationTypeFromJSON(json: any): RefrigerantOperationType {
    return RefrigerantOperationTypeFromJSONTyped(json, false);
}

export function RefrigerantOperationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefrigerantOperationType {
    return json as RefrigerantOperationType;
}

export function RefrigerantOperationTypeToJSON(value?: RefrigerantOperationType | null): any {
    return value as any;
}

