/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TaxReductionTypeTypeName {
    ROT = 'rot',
    RUT = 'rut',
    Green = 'green',
    Other = 'other'
}

export function TaxReductionTypeTypeNameFromJSON(json: any): TaxReductionTypeTypeName {
    return TaxReductionTypeTypeNameFromJSONTyped(json, false);
}

export function TaxReductionTypeTypeNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxReductionTypeTypeName {
    return json as TaxReductionTypeTypeName;
}

export function TaxReductionTypeTypeNameToJSON(value?: TaxReductionTypeTypeName | null): any {
    return value as any;
}

