/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProtocolGroup,
    ProtocolGroupFromJSON,
    ProtocolGroupFromJSONTyped,
    ProtocolGroupToJSON,
    ProtocolType,
    ProtocolTypeFromJSON,
    ProtocolTypeFromJSONTyped,
    ProtocolTypeToJSON,
    WorkObjectType,
    WorkObjectTypeFromJSON,
    WorkObjectTypeFromJSONTyped,
    WorkObjectTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Protocol
 */
export interface Protocol {
    /**
     * 
     * @type {string}
     * @memberof Protocol
     */
    id: string;
    /**
     * 
     * @type {ProtocolType}
     * @memberof Protocol
     */
    type: ProtocolType;
    /**
     * 
     * @type {boolean}
     * @memberof Protocol
     */
    appliesToFacilities: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Protocol
     */
    appliesToWorkObjects: boolean;
    /**
     * 
     * @type {WorkObjectType}
     * @memberof Protocol
     */
    appliesToWorkObjectType?: WorkObjectType | null;
    /**
     * 
     * @type {string}
     * @memberof Protocol
     */
    number?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Protocol
     */
    active?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Protocol
     */
    title?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Protocol
     */
    showNotes: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Protocol
     */
    collapsible: boolean;
    /**
     * 
     * @type {Array<ProtocolGroup>}
     * @memberof Protocol
     */
    groups: Array<ProtocolGroup>;
    /**
     * The assesment options available to each protocol item.
See String}) for defaults.
     * @type {Array<string>}
     * @memberof Protocol
     */
    assessments: Array<string>;
}

export function ProtocolFromJSON(json: any): Protocol {
    return ProtocolFromJSONTyped(json, false);
}

export function ProtocolFromJSONTyped(json: any, ignoreDiscriminator: boolean): Protocol {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': ProtocolTypeFromJSON(json['type']),
        'appliesToFacilities': json['appliesToFacilities'],
        'appliesToWorkObjects': json['appliesToWorkObjects'],
        'appliesToWorkObjectType': !exists(json, 'appliesToWorkObjectType') ? undefined : WorkObjectTypeFromJSON(json['appliesToWorkObjectType']),
        'number': !exists(json, 'number') ? undefined : json['number'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'showNotes': json['showNotes'],
        'collapsible': json['collapsible'],
        'groups': ((json['groups'] as Array<any>).map(ProtocolGroupFromJSON)),
        'assessments': json['assessments'],
    };
}

export function ProtocolToJSON(value?: Protocol | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': ProtocolTypeToJSON(value.type),
        'appliesToFacilities': value.appliesToFacilities,
        'appliesToWorkObjects': value.appliesToWorkObjects,
        'appliesToWorkObjectType': WorkObjectTypeToJSON(value.appliesToWorkObjectType),
        'number': value.number,
        'active': value.active,
        'title': value.title,
        'showNotes': value.showNotes,
        'collapsible': value.collapsible,
        'groups': ((value.groups as Array<any>).map(ProtocolGroupToJSON)),
        'assessments': value.assessments,
    };
}


