/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CostCenter
 */
export interface CostCenter {
    /**
     * 
     * @type {string}
     * @memberof CostCenter
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CostCenter
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof CostCenter
     */
    name: string;
}

export function CostCenterFromJSON(json: any): CostCenter {
    return CostCenterFromJSONTyped(json, false);
}

export function CostCenterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostCenter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'name': json['name'],
    };
}

export function CostCenterToJSON(value?: CostCenter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'name': value.name,
    };
}


