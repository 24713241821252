/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Supplier,
    SupplierFromJSON,
    SupplierFromJSONTyped,
    SupplierToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectSupplierInvoice
 */
export interface ProjectSupplierInvoice {
    /**
     * 
     * @type {string}
     * @memberof ProjectSupplierInvoice
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSupplierInvoice
     */
    invoiceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSupplierInvoice
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSupplierInvoice
     */
    path?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSupplierInvoice
     */
    totalAmount?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectSupplierInvoice
     */
    invoiceDate?: Date | null;
    /**
     * 
     * @type {Supplier}
     * @memberof ProjectSupplierInvoice
     */
    supplier?: Supplier | null;
}

export function ProjectSupplierInvoiceFromJSON(json: any): ProjectSupplierInvoice {
    return ProjectSupplierInvoiceFromJSONTyped(json, false);
}

export function ProjectSupplierInvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSupplierInvoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'invoiceNumber': !exists(json, 'invoiceNumber') ? undefined : json['invoiceNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'totalAmount': !exists(json, 'totalAmount') ? undefined : json['totalAmount'],
        'invoiceDate': !exists(json, 'invoiceDate') ? undefined : (json['invoiceDate'] === null ? null : new Date(json['invoiceDate'])),
        'supplier': !exists(json, 'supplier') ? undefined : SupplierFromJSON(json['supplier']),
    };
}

export function ProjectSupplierInvoiceToJSON(value?: ProjectSupplierInvoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'invoiceNumber': value.invoiceNumber,
        'name': value.name,
        'path': value.path,
        'totalAmount': value.totalAmount,
        'invoiceDate': value.invoiceDate === undefined ? undefined : (value.invoiceDate === null ? null : value.invoiceDate.toISOString()),
        'supplier': SupplierToJSON(value.supplier),
    };
}


