/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkOrderPlanSortOrder {
    NextDate = 'nextDate',
    Week = 'week',
    Customer = 'customer',
    Facility = 'facility'
}

export function WorkOrderPlanSortOrderFromJSON(json: any): WorkOrderPlanSortOrder {
    return WorkOrderPlanSortOrderFromJSONTyped(json, false);
}

export function WorkOrderPlanSortOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkOrderPlanSortOrder {
    return json as WorkOrderPlanSortOrder;
}

export function WorkOrderPlanSortOrderToJSON(value?: WorkOrderPlanSortOrder | null): any {
    return value as any;
}

