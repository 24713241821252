



































import Vue from 'vue';
import { SDFData, SDFDataRequestError } from '../SDFData';
import _debounce from 'lodash.debounce';
import moment from 'moment'

export default Vue.component('waste-list', {
    data(): any {        
        return {
            loadSuccessful: false,
            loadError: '',
            currentId: '',
            list: [],
            nextLink: null,
            filteredWasteList: [],
            filterText: '',
            isActive: false,
        }
    },
    mounted: function () {
        this.updateList(true);
    },
    created () {
        window.addEventListener('scroll', this.loadMoreDebounce);
    },
    destroyed () {
        window.removeEventListener('scroll', this.loadMoreDebounce);
    },

    computed: {
        filtersActive(): boolean {
            return this.filterText != '';
        }
    },
    watch: {
        filterText() {
            _debounce(function (this: any) { this.updateList(true); }, 500).call(this);
        }

    },
    methods: {
        getFormattedDate : function (date: any) {
            return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
        },
        updateList(clear: boolean) {
            const self = this;
            const queryList = new Array<string>();
           
            if (clear) {
                this.nextLink = 'wasteobjects?' + 'filterText=' + encodeURIComponent(this.filterText) + '?isActive=' + this.isActive;
            }
            SDFData.request(this.nextLink)
                .then(function (json: any) {
                    self.nextLink = json.nextLink;

                    if (clear) self.list = json.items;
                    else self.list.push.apply(self.list, json.items);

                    self.loadSuccessful = true;
                    self.filteredWasteList = self.list;
                    self.loadMoreDebounce();
                    SDFData.setBreadCrumb(self.$route.path, 'Avfall');
                })
                .catch(function (error: any) {
                    self.loadError = JSON.stringify(error);
                    if(error.status === 403){
                        alert('Behörighet saknas');
                    }
                    self.loadSuccessful = false;
                });
        },

        clearFilter() {
            this.filterText = '';
        },

        loadMoreDebounce: _debounce(function (this: any) {
            if (!this.nextLink) return;
            const margin = 1000;
            const bottomOfWindow = (window.innerHeight + window.pageYOffset) >= document.documentElement.scrollHeight - margin;
            if (!bottomOfWindow) return;

            this.updateList(false);
        }, 100),
                getRelativeDate(date: string) {
            return SDFData.getRelativeDate(new Date(date));
        }     
    }
});
