/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CoordinateSystem {
    Wgs84 = 'wgs84',
    Sweref99Tm = 'sweref99Tm'
}

export function CoordinateSystemFromJSON(json: any): CoordinateSystem {
    return CoordinateSystemFromJSONTyped(json, false);
}

export function CoordinateSystemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoordinateSystem {
    return json as CoordinateSystem;
}

export function CoordinateSystemToJSON(value?: CoordinateSystem | null): any {
    return value as any;
}

