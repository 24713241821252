/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProtocolType {
    Custom = 'custom',
    FGas = 'fGas',
    Unaccepted = 'unaccepted'
}

export function ProtocolTypeFromJSON(json: any): ProtocolType {
    return ProtocolTypeFromJSONTyped(json, false);
}

export function ProtocolTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolType {
    return json as ProtocolType;
}

export function ProtocolTypeToJSON(value?: ProtocolType | null): any {
    return value as any;
}

