/* tslint:disable */
/* eslint-disable */
/**
 * SDF API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProtocolItem,
    ProtocolItemFromJSON,
    ProtocolItemFromJSONTyped,
    ProtocolItemToJSON,
} from './';

/**
 * Group of protocol items.
 * @export
 * @interface ProtocolGroup
 */
export interface ProtocolGroup {
    /**
     * The id of the protocol group.
     * @type {string}
     * @memberof ProtocolGroup
     */
    id: string;
    /**
     * The title of the protocol group.
     * @type {string}
     * @memberof ProtocolGroup
     */
    title: string;
    /**
     * The items in the group.
     * @type {Array<ProtocolItem>}
     * @memberof ProtocolGroup
     */
    items: Array<ProtocolItem>;
}

export function ProtocolGroupFromJSON(json: any): ProtocolGroup {
    return ProtocolGroupFromJSONTyped(json, false);
}

export function ProtocolGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'items': ((json['items'] as Array<any>).map(ProtocolItemFromJSON)),
    };
}

export function ProtocolGroupToJSON(value?: ProtocolGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'items': ((value.items as Array<any>).map(ProtocolItemToJSON)),
    };
}


